import { Layout, Typography, Button, Calendar, Space, Card, Form, Row, Col, Select, TimePicker, Skeleton, Tooltip, Modal, DatePicker, Divider, Input, Alert, InputNumber, Tag, message, Spin } from "antd";
import { DeleteOutlined, PlusCircleOutlined, LeftOutlined, UserAddOutlined, MailOutlined, EditOutlined, MinusCircleOutlined, PhoneOutlined, EnvironmentOutlined, InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";
import { LessonsList } from "./MobileCalendar";
// @ts-ignore
import _ from 'lodash';
import { DayLessonsCard } from "./DayLessons/DayLessonsCard";
import { NotApprovedCard } from "./DayLessons/NotApprovedCard";
import { HowManyLessonsToOneContext } from '../../helpers/HowManyLessonsToOneContext';

const { Header, Content } = Layout;
const { confirm } = Modal;
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

moment.updateLocale("lv", {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    week: {
        dow: 1,
    },
    months: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
    monthsShort: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
});

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

const errorStatuses = {
    "other_reserved": "Kāds cits jau ir rezervējis šo laiku",
    "slot_not_active": "Nodarbība nav aktīva",
    "lesson_start_time_offset_fail": "Nodarbības rezevācijas laiks ir pārāk tuvu",
    "student_already_signed_to_datetime": "Šis laiks jau ir rezervēts",
    "someone_already_bought": "Kāds cits jau ir rezervējis šo laiku",
    "student_dont_have_category": "Students nav šīs kategorijas",
    "daily_driving_limit_reached": "Dienas braukšanas limits sasniegts",
    "not_enough_balance": "Nepietiek līdzekļu",
    "student_not_found": "Students netika atrasts",
    "short_phone_nr": "Nepareizs tālruņa numurs",
} as any;

const drivingOptions = {
    "field": "Laukums",
    "city": "Satiksme",
} as any

export type CalendarPageProps = RouteComponentProps & {
    context: any;
};

export type CalendarPageState = {
    instructor: any;
    timeSlotTemplates: any;
    loaded: boolean;
    loading: boolean;
    timeSlots: any;
    prompt: null | number;
    showAddSlotModal: boolean;
    filterSlotsBy: "all" | "taken" | "free";
    initialDate: any;
    showVacationModal: boolean;
    vacations: any;
    vacationTemp: any;
    vacationEdit: any;
    minVacationDate: any;
    addStudentError: String;
    todaySlots: []
};

const ChangeCityAndCategoryForm = (props: any) => {
    const { howManyLessonsToOne, lessonCountNames } = useContext(HowManyLessonsToOneContext);
    const [service, setService] = React.useState(props.slot.instructor_service);
    const [city, setCity] = React.useState(props.slot.city_id);

    const saveNewSlotData = () => {
        const data = {
            ...props.slot,
            instructor_service_id: service,
            city_id: city,
        }

        http.patch(`time_slots/${props.slot.slot_id}`, { ...data }).then((response) => {
            props.closeForm();
            props.refresh();
        })
    }

    useEffect(() => {
        setCity(props.slot.city_id)
    }, [service]);

    return <div>
        <Row justify="end">
            <Button
                onClick={() => props.closeForm()}
            >
                Atcelt
            </Button>
        </Row>
        <div style={{ paddingTop: 20 }}>
            <p style={{ marginBottom: 8 }}>Pakalpojums:</p>
            <Select
                value={service}
                onChange={(e) => setService(e)}
                style={{ width: "100%", marginBottom: 14 }}
            >
                {
                    props.services.filter((s: any) => {
                        return howManyLessonsToOne.some((count: Number) => count === props.slot?.how_many_lessons) ? s.category?.[lessonCountNames[props.slot?.how_many_lessons]] : !s.category.exam && !s.category.test_drive;
                    }).map((service: any) => {
                        return <Option key={service.id} value={service.id}>{service.category.value} ({service.vehicle_and_plate})</Option>
                    })
                }
            </Select>
            <p style={{ marginBottom: 8 }}>Pakalpojums:</p>
            <Select
                value={city}
                onChange={(e) => setCity(e)}
                style={{ width: "100%", marginBottom: 20 }}
            >
                {
                    props.services.find((el: any) => el.id === service).cities.map((city: any) => {
                        return <Option key={city.id} value={city.id}>{city.value}</Option>
                    })
                }
            </Select>
            <Row justify="end">
                <Button
                    type="primary"
                    onClick={saveNewSlotData}
                    disabled={city === props.slot.city_id && service === props.slot.instructor_service}
                >
                    Saglabāt
                </Button>
            </Row>
        </div>
    </div>
}

function TimeSlotEditor(props: any) {
    const [form] = Form.useForm();
    const [showWarning, setShowWarning] = React.useState(false);
    const [date, setDate] = React.useState(props.initialData.date.format(dateFormat));
    const [time, setTime] = React.useState(props.initialData.time_from.format(timeFormat));
    const cities = props.service.cities

    const decideIfToShowWarning = (d: any, t: any, timeSlots: any) => {
        const slots = timeSlots.filter((timeSlot: any) => {
            return (
                timeSlot.id !== props.slotId &&
                timeSlot.date === date &&
                moment(timeSlot.time_from, "HH:mm:ss").format(timeFormat) === time &&
                timeSlot.slot_status !== "instructor_cancelled"
            );
        });
        if (slots.length > 0) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }

    const onDateTimeChange = (dt: 'date' | 'time') => (value: any) => {
        let d: any, t: any;

        if (dt === 'date') {
            t = time;
            d = value.format(dateFormat);
            setDate(d);
        } else {
            d = date;
            t = value.format(timeFormat);
            setTime(t);
        }
    }

    React.useEffect(() => {
        decideIfToShowWarning(date, time, props.timeSlots);
    });

    const warning = <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={24}>
            <Alert
                message="Uzmanību"
                description="Šis laiks jau ir aizņemts!"
                type="warning"
                showIcon
            />
        </Col>
    </Row>

    return <Form
        name="edit_slot_form"
        form={form}
        initialValues={props.initialData}
        onFinish={props.onSave}
        layout="vertical"
    >
        {/* {
            cities?.length > 1
                ? <Form.Item name="city_id" rules={[{ required: true, message: "Obligāts lauks" }]}>
                    <Select placeholder="Pilsēta">
                        {cities.map((city: any) => {
                            return <Option value={city.id}>{city.value}</Option>
                        })}
                    </Select>
                </Form.Item>
                : <div style={{ marginBottom: 24 }}>{cities[0]?.value}</div>
        } */}
        <Form.Item name="how_many_lessons">
            <Select>
                {props.studentLessonKindsArray.filter((item: any, index: any) => {
                    return props.service.category_exam ? index === 4 : props.service?.category?.test_drive ? index === 5 : index < 4;
                }).map((t: any, i: any, self: any) => {
                    const key = self.length > 1 ? i + 1 : props.service?.category?.test_drive ? 6 : 5 ;
                    return <Select.Option key={`sfd-${key}`} value={key}>{t}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Row gutter={20}>
            <Col span={12}>
                <Form.Item label="Datums" name="date">
                    <DatePicker allowClear={false} onChange={onDateTimeChange('date')} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Nodarbības sākums" name="time_from">
                    <TimePicker allowClear={false} onChange={onDateTimeChange('time')} minuteStep={15} format={timeFormat} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Komentārs" name="comment">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
                {
                    !!props.service?.category?.driving_options
                    && <Form.Item label="Braukšanas uzstādījumi" name="driving_option">
                        <Select >
                            <Select.Option value={""}>{""}</Select.Option>
                            {
                                Object.keys(drivingOptions).map((option: any, index: any) => {
                                    return <Select.Option key={index} value={option}>{drivingOptions[option]}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }
            </Col>
        </Row>
        {showWarning ? warning : null}
        <Divider />
        <Form.Item>
            <Space>
                <Button htmlType="submit" type="primary">Saglabāt</Button>
                <Button onClick={props.onCancel}>Atcelt izmaiņas</Button>
            </Space>
        </Form.Item>
    </Form>
}

export function TimeSlot(props: any) {
    const {
        slot: s,
        deleteTimeSlot,
        updateTimeSlots,
        loading,
        services
    } = props;

    // const cityService = s.instructor_service.city.find((el: any) => el.city_id === s.city_id);
    const cityObj = services.find((el: any) => {
        return el.id === s.instructor_service
    }).city_services.filter((city: any) => {
        return city.city_id === s.city_id;
    });

    const city = services.find((el: any) => {
        return el.id === s.instructor_service
    }).cities.find((city: any) => {
        return city.id === s.city_id;
    });

    const [isModalOpen, setModalOpenState] = useState(false);
    const [studentPhone, setStudentPhone] = useState('');
    const [studentPhoneError, setStudentPhoneError] = useState(false);
    const [studentBalanceError, setStudentBalanceError] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [addingStudent, setAddingStudent] = useState(false);
    const [error, setError] = useState("");
    const [changeServiceOrCity, setChangeServiceOrCity] = useState(false);

    const tf = moment(s.time_from, "HH:mm:ss").format(timeFormat);
    const tt = moment(s.time_to, "HH:mm:ss").format(timeFormat);
    // const studentLesson = s.student_lessons[0];

    const makeActive = (e: any) => {
        e.stopPropagation();
        setModalOpenState(true);
    }

    const makeInactive = (e: any) => {
        e.stopPropagation()
        setModalOpenState(false);
        cancelEditing();
    }

    useEffect(() => {
        if (!loading) {
            setAddingStudent(false);
        }
    }, [loading])

    const addStudent = () => {
        setAddingStudent(true);
        setStudentPhoneError(false);
        setStudentBalanceError(false);
        http.post(`time_slots/${s.slot_id}/add_student`, { phone_number: studentPhone }).then((response) => {
            // setAddingStudent(false);
            setError("");
            updateTimeSlots(response.data.data);
        }).catch((e: any) => {
            if (e.response && e.response.data.data.reason) {
                setError(e.response.data.data.reason);
                setStudentPhoneError(true);
            } else if (e.response && e.response.data.message === "Not enough balance.") {
                setStudentBalanceError(true);
            }
            setAddingStudent(false);
        });
    }

    const removeStudent = () => {
        let cancelComment = "";
        Modal.confirm({
            title: "Atcelt studenta nodarbību",
            content: <div>
                <p>Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību:</p>
                <Input.TextArea
                    onChange={(e) => cancelComment = e.target.value}
                    style={{ width: "100%" }}
                />
            </div>,
            onOk() {
                if (!cancelComment) {
                    message.error("Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību");
                    return;
                }
                http.post(`time_slots/${s.slot_id}/remove_student`, {
                    instructor_cancel_comment: cancelComment
                }).then((response) => {
                    updateTimeSlots(response.data.data);
                }).catch((e: any) => {
                    console.error("error in remove_student", e);
                });
            },
        })
        // http.post(`time_slots/${s.slot_id}/remove_student`).then((response) => {
        //     updateTimeSlots(response.data.data);
        // }).catch((e: any) => {
        //     console.error("error in remove_student", e);
        // });
    }

    const changeStudentPhone = (e: any) => {
        setStudentPhone(e.target.value);
        setStudentPhoneError(false);
        setStudentBalanceError(false);
    }

    const contentStyle: any = {
        borderRadius: 6,
        padding: 0,
        fontSize: 12,
        border: "1px solid transparent",
        paddingLeft: 4,
        paddingRight: 4
    };

    const hasStudents = s.student_id !== null;

    if (hasStudents && (s.student_status === "done" || s.student_status === "exam_passed" || s.student_status === "exam_failed")) {
        contentStyle.backgroundColor = "#90ee90";
    } else if (hasStudents && (s.student_status === "new" || s.student_status === "exam" || s.student_status === "test_drive")) {
        contentStyle.backgroundColor = "#d0d0d0";
    } else if (!hasStudents) {
        contentStyle.backgroundColor = "#d0d0d0";
    }
    else {
        contentStyle.backgroundColor = "#f8d2d2";
    }

    if (hasStudents) {
        contentStyle.fontWeight = "bold";
    } else {
        contentStyle.backgroundColor = "transparent";
        contentStyle.color = "#666";
        contentStyle.border = "1px solid #aaa";
    }

    const canRemoveStudent = hasStudents && s.student_status === "new" && moment(s.date, "YYYY-MM-DD").isSameOrAfter(moment(), "date") && (!!s.allow_bought_slot_del || window.sessionStorage.getItem('admin'));

    // const city = props.cities.filter((city: any) => {
    //     return city.id === s.city_id;
    // })[0];

    const content = (
        <div style={contentStyle} onClick={makeActive}>
            <div>
                {tf} - {tt} &nbsp;&nbsp;&nbsp;&nbsp;
                {`${props.date} ${s.category_letter} 
                (${s.transmission[0].toUpperCase()})
                ${city !== undefined ? city.value : ""}
                ${s.driving_option ? `(${drivingOptions[s.driving_option]})` : ""}
                ${hasStudents ? s.student_name : ""}`}
                {s.comment ? <InfoCircleOutlined style={{ marginLeft: 10 }} /> : <></>}
            </div>
        </div>
    )

    const title = hasStudents
        ? `${s.category_letter} kategorija`
        : `${s.category_letter} kategorija`;

    const timeSlotTitle = hasStudents
        ? `${s.category_letter} kategorija
    ${s.comment || ""}`
        : `${s.category_letter} kategorija
    ${s.comment || ""}`;

    const startEditing = () => {
        setIsEditing(true);
    };

    const cancelEditing = () => {
        setIsEditing(false);
    }

    const saveEditing = (data: any) => {
        const time_from = data.time_from.format(timeFormat);
        let time_to = data.time_from.clone();

        if (data.how_many_lessons == 5 || data.how_many_lessons == 6) {
            time_to.add(1, 'hours');
        } else {
            time_to.add(data.how_many_lessons * 45, "minutes");
        }

        time_to = time_to.format(timeFormat);

        const date = data.date.format(dateFormat);
        const how_many_lessons = data.how_many_lessons;
        const comment = data.comment;
        const city_id = data.city_id;
        const driving_option = data?.driving_option && data?.driving_option?.length > 0 ? data?.driving_option : null;
        const patchData = { time_from, time_to, date, how_many_lessons, comment, city_id, driving_option };

        http.patch(`time_slots/${s.slot_id}`, patchData).then((response: any) => {
            updateTimeSlots(response.data.data);
            setIsEditing(false);
        }).catch((e: any) => {
            setIsEditing(false);
            console.error("error in saveEditing slot", e);
        })
    }

    const editingLayout = (
        <TimeSlotEditor
            onCancel={cancelEditing}
            onSave={saveEditing}
            service={services.find((el: any) => el.id === s.instructor_service)}
            initialData={{
                time_from: moment(s.time_from, "HH:mm:ss"),
                time_to: moment(s.time_to, "HH:mm:ss"),
                how_many_lessons: s.how_many_lessons,
                date: moment(s.date),
                comment: s.comment,
                city_id: s.city_id,
                cities: s.cities,
                driving_option: s.driving_option,
            }}
            studentLessonKindsArray={props.studentLessonKindsArray}
            timeSlots={props.timeSlots}
            slotId={s.slot_id}
        />
    );

    const normalLayout = (
        changeServiceOrCity
            ? <ChangeCityAndCategoryForm
                closeForm={() => {
                    setChangeServiceOrCity(false);
                    setModalOpenState(false);
                }}
                services={services}
                slot={s}
                refresh={updateTimeSlots}
            />
            : <>
                {!hasStudents
                    ? <>
                        <div style={{ marginBottom: 20 }}>
                            {
                                s.regenerated !== null || s.regenerated > 0
                                    ? <Tag color="green">Slots ir uzģenerēts, jo kāds students ir atteicies. Nav iespējams dzēst!</Tag>
                                    : (moment(s.date).isAfter(moment(), "day") || (moment(s.date).isSame(moment(), "day") && moment(s.time_from, "HH:mm:ss").add(30, "minutes").isAfter(moment(), "minute")))
                                    && <Space style={{ flexWrap: "wrap" }}>
                                        <Button onClick={startEditing} size={"small"} style={{ marginTop: 10 }} icon={<EditOutlined />}>Labot</Button>
                                        <Button onClick={deleteTimeSlot} danger size={"small"} style={{ marginTop: 10 }} icon={<DeleteOutlined />}>Dzēst nodarbību</Button>
                                        {!!!s.student_name && <Button onClick={() => setChangeServiceOrCity(true)} danger size={"small"} style={{ marginTop: 10 }}>Mainīt pilsētu/pakalpojumu</Button>}
                                    </Space>
                            }

                        </div>
                    </>
                    : null}
                {content}
                <div style={{ marginTop: 20 }}>
                    <Space>
                        <span><EnvironmentOutlined /></span>
                        {/* <Button onClick={() => console.log(cityObj)}>!City</Button> */}
                        <span>{cityObj[0]?.meeting_place}, {city?.value}</span>
                    </Space>
                </div>
                {
                    s?.driving_option
                    && <div style={{ marginTop: 20 }}>
                        <Space>
                            <span><b>Papildus braukšanas uzstādījumi: </b>{drivingOptions[s.driving_option]}</span>
                        </Space>
                    </div>
                }
                <div>
                    {!hasStudents
                        ? <>
                            <Divider />
                            <Space>
                                <InfoCircleOutlined />
                                <Text>{s.comment || ""}</Text>
                            </Space>

                            {
                                !s.category_exam
                                    ? <>
                                        <Divider />
                                        <div style={{ marginBottom: 2 }}>Pievienot studentu</div>
                                        <Space>
                                            <Input
                                                value={studentPhone}
                                                onChange={changeStudentPhone}
                                                placeholder="Tālruņa numurs"
                                            />
                                            <Button type='primary' loading={addingStudent} onClick={addStudent} icon={<UserAddOutlined />} />
                                        </Space>
                                    </>
                                    : <></>
                            }
                            {
                                error.length > 0
                                && <div style={{ marginTop: 2 }}>
                                    <Text type="danger">{errorStatuses[error] ?? error}</Text>
                                </div>
                            }
                            {/* {studentPhoneError
                            ? <div style={{ marginTop: 2 }}>
                                <Text type="danger">Students netika atrasts</Text>
                            </div>
                            : null
                        }
                        {studentBalanceError
                            ? <div style={{ marginTop: 2 }}>
                                <Text type="danger">Studentam nepietiek līdzekļu</Text>
                            </div>
                            : null
                        } */}
                        </>
                        : <>
                            <Divider />
                            <div>
                                <Space>
                                    <InfoCircleOutlined />
                                    <Text>{s.comment || ""}</Text>
                                </Space>
                            </div>
                            <Divider />
                            <div style={{ marginBottom: 5, fontSize: 12 }}>Students</div>
                            <Space>
                                <Link to={`/student-stats/${s.student_id}`}><strong>{s.student_name} <LinkOutlined /></strong></Link>
                            </Space>
                            <br />
                            <Space>
                                <span><PhoneOutlined /></span>
                                <a href={`tel:${s.phone_number}`}>{s.phone_number}</a>
                            </Space>
                            <br />
                            <Space>
                                <span><MailOutlined /></span>
                                <span>{s.email}</span>
                            </Space>
                            {canRemoveStudent
                                ? <>
                                    <br />
                                    <Space>
                                        <Button onClick={removeStudent} danger size={"small"} style={{ marginTop: 10 }} icon={<MinusCircleOutlined />}>Noņemt studentu</Button>
                                    </Space>
                                </>
                                : null}
                        </>
                    }
                    <Divider />
                    <Space>
                        <Button onClick={makeInactive}>Aizvērt nodarbības logu</Button>
                    </Space>
                </div>
            </>
    );

    return (
        <div key={`bubam-${s.slot_id}`} style={{ margin: 2 }} title={timeSlotTitle}>
            {content}
            <Modal
                onCancel={makeInactive}
                title={`${title}`}
                footer={null}
                visible={isModalOpen}
            >
                {isEditing ? editingLayout : normalLayout}
            </Modal>
        </div>
    );
}

export class BaseCalendarPage extends React.Component<CalendarPageProps, CalendarPageState> {
    studentLessonKindsArray: any;
    studentLessonKinds: any;
    calendarLegend = [
        {
            text: "Nav ieplānota nodarbība",
            color: "#FFFFFF"
        },
        {
            text: "Ir ieplānota nodarbība",
            color: "#D0D0D0"
        },
        {
            text: "Apmeklēta nodarbība",
            color: "#90EE90"
        },
        {
            text: "Neapmeklēta nodarbība",
            color: "#F8D2D2"
        }
    ]

    constructor(props: CalendarPageProps) {
        super(props);
        this.state = {
            instructor: {
                services: []
            },
            timeSlotTemplates: [],
            timeSlots: [],
            loaded: false,
            loading: false,
            prompt: null,
            showAddSlotModal: false,
            filterSlotsBy: "all",
            initialDate: moment(),
            showVacationModal: false,
            minVacationDate: moment(),
            vacations: [],
            vacationTemp: [undefined, undefined],
            vacationEdit: [undefined, undefined],
            addStudentError: "",
            todaySlots: []
        };

        this.studentLessonKindsArray = [
            "1 x 45 min (1 students)",
            "2 x 45 min (1 students)",
            "3 x 45 min (1 students)",
            "4 x 45 min (1 students)",
            "Eksāmens (1 x 60min)",
            "Testa brauciens (1 x 60min)"
        ];

        this.studentLessonKinds = {
            "1": 0,
            "2": 1,
            "3": 2,
            "4": 3,
            "5": 4,
            "6": 5,
        };

    }

    mobileAddSlotModal = (date: any = moment()) => {
        this.setState({ initialDate: moment(date) });
        this.setState({ showAddSlotModal: true });
    };

    fetchTimeSlots = async (mobile: boolean = false, period: any = []) => {
        this.setState({ loading: true });
        if (!mobile) {
            const timeSlots = await http.get(`time_slots`, {
                params: {
                    month: moment(this.state.initialDate).format("MM"),
                    year: moment(this.state.initialDate).format("YYYY"),
                    new: 1
                }
            });
            this.setState({
                timeSlots: timeSlots.data.data,
                todaySlots: timeSlots.data.data.filter((el: any) => moment(el.date, "YYYY-MM-DD").isSame(moment(), "date")),
                loading: false,
            })
        } else {
            const timeSlots = await http.get(`time_slots?from=${moment(period[0]).format("YYYY-MM-DD")}&to=${moment(period[1]).format("YYYY-MM-DD")}`);
            this.setState({
                timeSlots: timeSlots.data.data,
                todaySlots: timeSlots.data.data.filter((el: any) => moment(el.date, "YYYY-MM-DD").isSame(moment(), "date")),
                loading: false,
            })
        }
    }

    async componentDidMount() {
        (window as any)['http'] = http;
        (window as any)['calendar'] = this;
        const instructor = await http.get('self').then((response: any) => {
            return response.data.data
        });
        const timeSlotTemplates = await http.get('time_slot_templates');
        this.fetchTimeSlots();
        // const timeSlots = await http.get(`time_slots?year=${moment(this.state.initialDate).format("YYYY")}&month=${moment(this.state.initialDate).format("MM")}`);
        this.fetchVacations(instructor.id);
        this.setState({
            instructor: instructor,
            timeSlotTemplates: timeSlotTemplates.data.data,
            // timeSlots: timeSlots.data.data,
            loaded: true,
        });
    }

    componentDidUpdate = async (prevProps: any, prevState: any) => {
        if (prevState.initialDate !== this.state.initialDate) {
            // console.log(prevState.initialDate.isSame(this.state.initialDate, 'month'))
            if (!prevState.initialDate.isSame(this.state.initialDate, 'month')) {
                this.fetchTimeSlots();
            }
        }
    }

    fetchVacations = async (id: any) => {
        const minVacDate = await http.get('/instructor/get-vacation-date');
        const vacationsArr = await http.get(`/instructor/get-instructor-vacations/${id}`);
        this.setState({
            minVacationDate: moment(minVacDate.data, "YYYY-MM-DD"),
            vacations: vacationsArr.data,
            loaded: true
        });
    }

    makeStudentLessonKind(tpl: any) {
        return `${tpl.how_many_lessons}`;
    }

    deleteTemplate = (tplId: number) => {
        return () => {
            this.setState({
                timeSlotTemplates: this.state.timeSlotTemplates.filter((tpl: any) => tpl.id !== tplId)
            })
        }
    }

    changeField = (tplId: number, field: string, serviceId: any = 1) => {

        let howManyLessons: any;

        return (e: any) => {
            let value: any;

            if (field === "time_to") {
                return;
            }

            let template = this.state.timeSlotTemplates.filter((t: any) => t.id === tplId);
            if (template.length === 0) { return; }
            template = template[0];
            let time_to = null as any;

            if (field === "time_from") {
                const serviceCategory = this.state.instructor.services
                    .find((el: any) => el.id === serviceId).category;
                howManyLessons = serviceCategory.exam
                    ? 5
                    : serviceCategory.test_drive
                        ? 6
                        : 1
                value = e.format(timeFormat);
                time_to = e.clone();

                if (this.props.context.howManyLessonsToOne.some((count: number) => count === howManyLessons)) {
                    time_to.add(1, 'hours');
                } else {
                    time_to.add(
                        Number(template.how_many_lessons) * 45,
                        "minutes"
                    );
                }
            }

            if (field === "comment") {
                value = e.target.value
            }

            if (field === "city_id") {
                value = e
            }

            if (field === "how_many_students") {
                value = Number(e)
            }

            if (field === "instructor_service_id") {
                const service = this.state.instructor.services.find((el: any) => el.id === e);
                howManyLessons = service?.category?.exam
                    ? 5
                    : service?.category?.test_drive
                        ? 6
                        : 1

                time_to = moment(template.time_from, timeFormat).clone();

                if (this.props.context.howManyLessonsToOne.some((count: number) => count === howManyLessons)) {
                    time_to.add(1, "hours");
                } else {
                    time_to.add(Number(howManyLessons) * 45, "minutes");
                }

                value = e;
            }

            if (field === "how_many_lessons") {
                value = e;
                time_to = moment(template.time_from, timeFormat).clone();

                if (this.props.context.howManyLessonsToOne.some((count: number) => count === value)) {
                    time_to.add(1, "hours");
                } else {
                    time_to.add(Number(value) * 45, "minutes");
                }
            }

            if (field === "driving_option") {
                value = e;
            }

            this.setState({
                timeSlotTemplates: this.state.timeSlotTemplates.map((tpl: any) => {
                    if (tpl.id === tplId) {
                        const newTpl = {
                            ...tpl,
                            [field]: value,
                        };
                        if (field === "instructor_service_id") {
                            newTpl.how_many_lessons = howManyLessons;
                            const service = this.state.instructor.services.find((el: any) => el.id === e);
                            // newTpl.driving_option = "";
                            if (!!!service.category.driving_options) {
                                newTpl.driving_options = 0;
                            } else {
                                newTpl.driving_options = 1;
                            }
                        }

                        if (time_to != null) {
                            newTpl.time_to = time_to.format(timeFormat);
                        }
                        return newTpl;
                    }

                    return tpl;
                })
            });
        }
    }

    renderForm(dayOfTheWeek: number) {
        let tpls = this.state.timeSlotTemplates.filter((tpl: any) => tpl.day_of_the_week === dayOfTheWeek);
        tpls = tpls.map((tpl: any) => {
            return {
                ...tpl,
                studentLessonKind: this.studentLessonKinds[this.makeStudentLessonKind(tpl)]
            }
        });
        return <div>
            {tpls.map((tpl: any) => {
                const thisService = this.state.instructor?.services
                    ?.find((el: any) => el.id === tpl.instructor_service_id)
                const howManyLessons = thisService.category.exam
                    ? 5
                    : thisService.category.test_drive
                        ? 6
                        : tpl.how_many_lessons;
                return <Form key={"tpl-" + tpl.id}>
                    <Row gutter={20}>
                        <Col flex={1}>
                            <Form.Item>
                                <Select style={{ width: 300 }}
                                    onChange={this.changeField(tpl.id, "instructor_service_id")}
                                    value={tpl.instructor_service_id}
                                >
                                    {this.state.instructor.services.map((s: any) => {
                                        return <Select.Option value={s.id} key={`service-${s.id}`}>
                                            {s.category.value} kategorija ({s.vehicle_and_plate})
                                        </Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            {
                                !!thisService?.category?.driving_options
                                && <Form.Item label="Braukšanas uzstādījumi" name="driving_option" initialValue={tpl.driving_option}>
                                    <Select
                                        onChange={this.changeField(tpl.id, "driving_option")}
                                        value={tpl.driving_option}
                                    >
                                        <Select.Option value={""}>{""}</Select.Option>
                                        {
                                            Object.keys(drivingOptions).map((option: any, index: any) => {
                                                return <Select.Option key={index} value={option}>{drivingOptions[option]}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col flex={1}>
                            <Form.Item>
                                <Select value={howManyLessons} onChange={this.changeField(tpl.id, "how_many_lessons")}>
                                    {this.studentLessonKindsArray.filter((item: any, index: any) => {
                                        const isExam = this.state.instructor.services.find((el: any) => el.id === tpl.instructor_service_id).category.exam;
                                        const isTestDrive = this.state.instructor.services.find((el: any) => el.id === tpl.instructor_service_id).category.test_drive;
                                        return isExam
                                            ? index === 4
                                            : isTestDrive
                                                ? index === 5
                                                : index < 4;
                                    }).map((t: any, i: any, self: any) => {
                                        const serviceCategory = this.state.instructor.services.find((el: any) => el.id === tpl.instructor_service_id).category
                                        return <Select.Option key={`sfd-${i}`} value={serviceCategory.exam ? 5 : serviceCategory.test_drive ? 6 : i + 1}>{t}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item initialValue={tpl.how_many_students} label="Studentu skaits" name="how_many_students">
                                <Select onChange={this.changeField(tpl.id, "how_many_students")}>
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item>
                                <TimePicker allowClear={false} minuteStep={15} onChange={this.changeField(tpl.id, "time_from", thisService.id)} format={timeFormat} value={moment(tpl.time_from, timeFormat)} />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item>
                                <TimePicker allowClear={false} disabled minuteStep={15} onChange={this.changeField(tpl.id, "time_to")} format={timeFormat} value={moment(tpl.time_to, timeFormat)} />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item name="comment" initialValue={tpl.comment}>
                                <Input.TextArea placeholder="Komentārs" autoSize={{ minRows: 2, maxRows: 2 }} onChange={this.changeField(tpl.id, "comment")} />
                            </Form.Item>
                        </Col>
                        <Col flex={1} style={{ minWidth: 130 }}>
                            <Form.Item
                                name="city_id"
                                initialValue={tpl.city_id}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Šis lauks ir obligāts',
                                    },
                                ]}>
                                <Select onChange={this.changeField(tpl.id, "city_id")}>
                                    {thisService.cities.map((city: any) => {
                                        return <Option key={city.id} value={city.id}>{city.value}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item>
                                <Button type="link" danger icon={<DeleteOutlined />} onClick={this.deleteTemplate(tpl.id)}></Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            })}
            <Button onClick={() => {
                function makeTemporaryId() {
                    const length = 20;
                    let result = '';
                    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    const charactersLength = characters.length;
                    for (let i = 0; i < length; i++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }
                    return `tmp_${result}`;
                }
                let timeSlotTemplates = this.state.timeSlotTemplates;
                let newTpl: any = {
                    comment: "",
                    city_id: this.state.instructor.services[0].cities[0].id,
                    instructor_service_id: this.state.instructor.services[0].id,
                    day_of_the_week: dayOfTheWeek,
                    time_from: "00:00:00",
                    time_to: "01:00:00",
                    how_many_students: 1,
                    how_many_lessons: !!this.state.instructor.services[0]?.category?.exam
                        ? 5
                        : !!this.state.instructor.services[0]?.category?.test_drive
                            ? 6
                            : 1,
                    kind: "sequential",
                    id: makeTemporaryId()
                };
                newTpl = {
                    ...newTpl,
                    studentLessonKind: this.studentLessonKinds[this.makeStudentLessonKind(newTpl)],
                    driving_option: ""
                }
                timeSlotTemplates = timeSlotTemplates.concat([newTpl]);
                this.setState({
                    timeSlotTemplates
                });
            }} style={{ padding: 0 }} icon={<PlusCircleOutlined />} type="link">Pievienot papildus nodarbības laiku</Button>
        </div>
    }

    renderTemplatePage() {
        return (
            <div style={{ margin: 15 }}>
                <Card style={{ marginBottom: 5 }} title="Pirmdiena">
                    {this.renderForm(1)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Otrdiena">
                    {this.renderForm(2)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Trešdiena">
                    {this.renderForm(3)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Ceturtdiena">
                    {this.renderForm(4)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Piektdiena">
                    {this.renderForm(5)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Sestdiena">
                    {this.renderForm(6)}
                </Card>

                <Card style={{ marginBottom: 5 }} title="Svētdiena">
                    {this.renderForm(7)}
                </Card>

                <Card style={{ marginBottom: 15 }}>
                    <Button onClick={this.saveForm} type="primary">Saglabāt</Button>
                </Card>
            </div>
        );
    }

    saveForm = () => {
        http.post('time_slot_templates/sync', {
            slots: this.state.timeSlotTemplates.map((tpl: any) => {
                const service = this.state.instructor.services.find((el: any) => el.id === tpl.instructor_service_id);
                if (!!!service?.category?.driving_options) {
                    delete tpl.driving_option;
                }
                if (String(tpl.id).startsWith("tmp_")) {
                    const res = { ...tpl };
                    delete res.id;
                    return res;
                }
                return tpl;
            })
        }).then(() => {
            this.props.history.push("/calendar");
        }).catch(() => {
            message.error('Kļūda');
        });
    }

    renderCalendarPage() {

        const updateFilterSlotsBy = (filterSlotsBy: any) => {
            this.setState({ filterSlotsBy });
        }

        return (
            <div style={{ margin: 15 }}>
                {
                    <Row gutter={16}>
                        <Col span={24} lg={14} xl={16}>
                            <DayLessonsCard todayLessons={this.state.todaySlots} updateTimeSlots={this.fetchTimeSlots} />
                        </Col>
                        <Col span={24} lg={10} xl={8}>
                            <NotApprovedCard updateTimeSlots={this.fetchTimeSlots} />
                        </Col>
                    </Row>
                }
                <Form>
                    <Form.Item label="Filtrēt pēc statusa">
                        <Select value={this.state.filterSlotsBy} onChange={updateFilterSlotsBy}>
                            <Select.Option value="all">Visas nodarbības</Select.Option>
                            <Select.Option value="taken">Rezervētās nodarbības</Select.Option>
                            <Select.Option value="free">Brīvās nodarbības</Select.Option>
                            <Select.Option value="exam">Eksāmeni</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
                <Spin spinning={this.state.loading}>
                    {window.matchMedia("(max-width: 767px)").matches ? (
                        <LessonsList
                            slots={this.state.timeSlots}
                            addSlot={this.mobileAddSlotModal}
                            dateCell={this.renderDateCell}
                            dateFormat={dateFormat}
                            fetchByPeriod={this.fetchTimeSlots}
                            loading={this.state.loading}
                        />
                    ) : (
                        <></>
                    )}
                    {window.matchMedia("(min-width: 768px)").matches ? (
                        <Calendar onSelect={(e: any) => this.setState({ initialDate: e })} dateCellRender={this.renderDateCell} />
                    ) : (
                        <></>
                    )}
                </Spin>
                <Card>
                    <Row align="middle">
                        {
                            Object.keys(this.calendarLegend)?.map((key: any) => {
                                return <div style={{
                                    display: "flex",
                                    marginRight: 20
                                }}>
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: 4,
                                            border: this.calendarLegend[key].color === "#FFFFFF" ? "1px solid #D4DADE" : "none",
                                            backgroundColor: this.calendarLegend[key].color,
                                            marginRight: 10
                                        }}
                                    />
                                    {this.calendarLegend[key].text}
                                </div>
                            })
                        }
                    </Row>
                </Card>
            </div>
        );
    }

    renderDateCell = (date: any, showFree = false) => {
        // console.log(this.state.timeSlots)
        const canceledStats = [
            'student_cancelled',
            'student_cancelled24',
            'student_cancelled48',
        ]
        const slots = this.state.timeSlots.filter((s: any) => {
            if (canceledStats.some(el => el === s.slot_status) && s.student_id === null) {
                return false;
            }
            if (s.date !== date.format(dateFormat) && !showFree) {
                return false;
            } else if (showFree) {
                return s.student_id === null;
                return s.student_lessons.length < 1;
            }
            if (this.state.filterSlotsBy === "all") {
                return true;
            } else if (this.state.filterSlotsBy === "taken") {
                return s.student_id !== null;
                return s.student_lessons.length > 0;
            } else if (this.state.filterSlotsBy === "free") {
                return s.student_id === null;
                return s.student_lessons.length < 1;
            } else if (this.state.filterSlotsBy === "exam") {
                return !!s.category_exam;
            }
        });

        // if (this.state.vacations.length > 0) {
        //     return this.state.vacations.map((vacation: any, index: any) => {
        //         if (date.isBetween(vacation.from, vacation.to, 'date', '[]')) {
        //             // console.log(vacation)
        //             return <Tag key={vacation + "-" + index} color="green">ATVAĻINĀJUMS</Tag>
        //         }
        //     });
        // }

        const vacation = this.state.vacations.some((vacation: any, index: any) => {
            if (date.isBetween(vacation.from, vacation.to, 'date', '[]')) {
                return vacation + "-" + index
            }
        });

        const notCancelledSlots = slots.filter((slot: any) => {
            return slot.slot_status !== "instructor_cancelled";
        })

        if (vacation && notCancelledSlots.length === 0) {
            return <Tag color="green">ATVAĻINĀJUMS</Tag>
        }

        if (slots.length === 0) {
            return null;
        }
        const sortedSlots = _.sortBy(notCancelledSlots, (lesson: any) => moment(lesson.time_from, "HH:mm:ss"))

        const makeDeleteTimeSlot = (slotId: any) => () => {
            http.delete(`time_slots/${slotId}`).then(() => {
                this.setState({
                    timeSlots: this.state.timeSlots.filter((slot: any) => slot.slot_id !== slotId)
                });
            });
        }

        const updateTimeSlots = (slots: any) => { this.fetchTimeSlots() };

        return <div>
            {sortedSlots.map((s: any) => <TimeSlot
                key={`time_slot-${s.slot_id}`}
                slot={s}
                deleteTimeSlot={makeDeleteTimeSlot(s.slot_id)}
                updateTimeSlots={updateTimeSlots}
                studentLessonKindsArray={this.studentLessonKindsArray}
                timeSlots={this.state.timeSlots}
                date={showFree ? s.date : ""}
                cities={this.state.instructor.cities}
                loading={this.state.loading}
                services={this.state.instructor.services}
            />)}
        </div>;
    }

    render() {
        const content = (
            <Switch>
                <Route exact path="/calendar">
                    {this.renderCalendarPage()}
                </Route>
                <Route exact path="/calendar/template">
                    {this.renderTemplatePage()}
                </Route>
            </Switch>
        );

        const noContent = (
            <div style={{ margin: 15 }}>
                <Card>
                    <Skeleton />
                </Card>
            </div>
        );

        const openAddSlotModal = () => {
            this.setState({ showAddSlotModal: true, addStudentError: "" });
        }

        const closeAddSlotModal = () => {
            this.setState({ showAddSlotModal: false });
        }

        const onAddSlot = (v: any) => {
            const newSlot = {
                instructor_service_id: v.instructor_service_id,
                date: v.date.format("YYYY-MM-DD"),
                time_from: v.time_from.format(timeFormat),
                // time_to: v.time_to.format(timeFormat),
                how_many_lessons: v.how_many_lessons,
                how_many_students: v.how_many_students,
                kind: "sequential",
                comment: v.comment,
                city_id: v.city_id,
                phone_number: v?.phone_number,
                driving_option: v?.driving_option && v?.driving_option?.length > 0 ? v?.driving_option : null,
            } as any;

            const time_to = v.time_from.clone();

            if (this.props.context.howManyLessonsToOne.some((count: number) => count === v.how_many_lessons)) {
                time_to.add(1, 'hours');
            } else {
                time_to.add(v.how_many_lessons * 45, "minutes");
            }

            newSlot["time_to"] = time_to.format(timeFormat);
            const promise = http.post('time_slots', newSlot);
            promise.then((r) => {
                this.fetchTimeSlots();
                this.setState({ showAddSlotModal: false });
            });
            promise.catch((err: any) => {
                if (err.response?.data?.data?.reason) {
                    message.error(errorStatuses[err.response?.data?.data?.reason] ?? err.response?.data?.data?.reason);
                }
                setTimeout(() => this.setState({ showAddSlotModal: false }), 3000);
            })
        }

        const initialValues = {
            student_lesson_kind: 0,
            instructor_service_id: this.state.instructor.services[0] ? this.state.instructor.services[0].id : null,
            time_from: moment().hour(moment().hours() + 1).minute(0), // moment("10:00", timeFormat),
            time_to: moment().hour(moment().hours() + 2).minute(0), // moment("11:00", timeFormat),
            date: this.state.initialDate,
            // how_many_lessons: 2,
        };

        const vacationModalHandler = () => {
            this.state.showVacationModal ? this.setState({ showVacationModal: false }) : this.setState({ showVacationModal: true })
        }

        const saveVacation = () => {
            if (this.state.vacationTemp[0] === undefined) {
                message.error('Lūdzu ievadiet atvaļinājuma datumus');
            } else {
                const data = {
                    from: moment(this.state.vacationTemp[0]).format("YYYY-MM-DD"),
                    to: moment(this.state.vacationTemp[1]).format("YYYY-MM-DD")
                }

                http.post('/instructor/create-vacation', data).then((response: any) => {
                    this.setState({ loaded: false })
                    this.fetchTimeSlots().then(() => {
                        this.fetchVacations(this.state.instructor.id);
                        this.setState({ vacationTemp: [undefined, undefined] })
                    });
                    vacationModalHandler();
                }).catch(() => {
                    message.error("Kļūda")
                })
            }
        }

        const editVacation = (id: any) => {
            const vac = this.state.vacations.find((el: any) => el.id === id);
            this.setState({ vacationEdit: [moment(vac.from, "YYYY-MM-DD"), moment(vac.to, "YYYY-MM-DD")] });
            let vacRange: any;
            confirm({
                title: "Labot atvaļinājumu",
                icon: <EditOutlined />,
                content: <div>
                    <RangePicker
                        defaultValue={[moment(vac.from, "YYYY-MM-DD"), moment(vac.to, "YYYY-MM-DD")]}
                        onChange={(values: any) => vacRange = values}
                        disabledDate={(current: any) => current < this.state.minVacationDate}
                        allowClear={false}
                    />
                </div>,
                onOk: () => {
                    const data = {
                        from: moment(vacRange[0]).format("YYYY-MM-DD"),
                        to: moment(vacRange[1]).format("YYYY-MM-DD")
                    }
                    http.post(`/instructor/update-vacation/${id}`, data).then((response: any) => {
                        this.fetchVacations(this.state.instructor.id);
                        this.setState({ vacationTemp: [undefined, undefined] })
                    }).catch(() => {
                        message.error("Kļūda")
                    });
                }
            })
        }

        const deleteVacation = async (id: any) => {
            await http.post(`/instructor/remove-vacation/${id}`).then((response: any) => {
                this.fetchVacations(this.state.instructor.id);
                message.success('Ieraksts ir dzēsts!');
            }).catch((err: any) => {
                message.error('Kļūda!');
            })
        }

        return (
            <Layout className="main-content" style={{ flexDirection: 'column' }}>
                <Modal
                    title="Pievienot nodarbību"
                    footer={null}
                    onCancel={closeAddSlotModal}
                    visible={this.state.showAddSlotModal}
                    destroyOnClose={true}
                >
                    <AddSlotForm
                        initialValues={initialValues}
                        onAddSlot={onAddSlot}
                        services={this.state.instructor.services}
                        studentLessonKindsArray={this.studentLessonKindsArray}
                        timeSlots={this.state.timeSlots}
                        error={this.state.addStudentError}
                    />
                </Modal>
                <Modal
                    title="Atvaļinājuma dienas"
                    visible={this.state.showVacationModal}
                    onCancel={vacationModalHandler}
                    onOk={saveVacation}
                >
                    <RangePicker
                        value={this.state.vacationTemp}
                        onChange={(values: any) => this.setState({ vacationTemp: values })}
                        disabledDate={(current: any) => current < this.state.minVacationDate}
                        allowClear={false}
                    />
                    {this.state.vacations.map((vacation: any) => {
                        return (
                            <Card key={vacation.id} style={{ marginTop: 8 }}>
                                <Row justify="space-between">
                                    <div>Atvaļinājums: No: {vacation.from} Līdz: {vacation.to}</div>
                                    <div>
                                        <Button onClick={() => editVacation(vacation.id)} style={{ fontSize: 16 }} type="text" icon={<EditOutlined />}></Button>
                                        <Button onClick={() => deleteVacation(vacation.id)} style={{ fontSize: 16 }} danger type="text" icon={<DeleteOutlined />}></Button>
                                    </div>
                                </Row>
                            </Card>
                        )
                    })}
                </Modal>
                <Row
                    className="header-row "
                    style={
                        {
                            background: "rgb(240, 242, 245)",
                            borderBottom: "2px solid #e7e7e7",
                        }
                    }
                >
                    <Switch>
                        <Route exact path="/calendar">
                            <Row justify="start" align="middle" gutter={16}>
                                <Link style={{ marginLeft: 10 }} to='/calendar/template'><Button type="primary">Nedēļas veidne</Button></Link>
                                <Button style={{ marginLeft: 10 }} onClick={this.state.instructor.services.length > 0 ? openAddSlotModal : console.log} icon={<PlusCircleOutlined />}>Pievienot nodarbību</Button>
                                <Button style={{ marginLeft: 10 }} onClick={vacationModalHandler}>Atvaļinājuma dienas</Button>
                                <Link style={{ marginLeft: 10 }} to="/exchange-cars">
                                    <Button>Maiņas auto</Button>
                                </Link>
                            </Row>
                        </Route>
                        <Route exact path="/calendar/template">
                            <Space>
                                <Link to='/calendar'><Button type="link" style={{ fontWeight: "bold" }} icon={<LeftOutlined />}>Kalendārs</Button></Link>
                            </Space>
                        </Route>
                    </Switch>
                </Row>
                <Content>
                    {this.state.loaded ? content : noContent}
                </Content>
            </Layout>
        );
    }
}

export function AddSlotForm(props: any) {
    const [form] = Form.useForm();
    const [showWarning, setShowWarning] = React.useState(false);
    const [date, setDate] = React.useState(props.initialValues.date.format(dateFormat));
    const [time, setTime] = React.useState(props.initialValues.time_from.format(timeFormat));
    const [serviceId, setServiceId] = useState(props.initialValues.instructor_service_id);
    const [isExam, setIsExam] = useState(false);
    const [isTestDrive, setIsTestDrive] = useState(false);
    const [disabled, setDisabled] = useState(false);
    // const [selectedTime, selectTime] = useState(moment() as any);
    const service = props.services.filter((service: any) => {
        return service.id === serviceId;
    });
    const cities = service[0].cities;

    const decideIfToShowWarning = (d: any, t: any, timeSlots: any) => {
        const slots = timeSlots.filter((timeSlot: any) => {
            return timeSlot.date === date && moment(timeSlot.time_from, "HH:mm:ss").format(timeFormat) === time &&
                timeSlot.slot_status !== "instructor_cancelled";
        });

        if (slots.length > 0) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }

    const onDateTimeChange = (dt: "date" | "time") => (value: any) => {
        let d: any, t: any;

        if (dt === 'date') {
            t = time;
            d = value.format(dateFormat);
            setDate(d);
        } else {
            d = date;
            t = value.format(timeFormat);
            setTime(t);
        }
    }

    const serviceChangeHandler = (e: any) => {
        setServiceId(e)
        props.services.find((el: any) => el.id === e).category.exam === 1
            ? setIsExam(true)
            : setIsExam(false)
        props.services.find((el: any) => el.id === e).category.test_drive === 1
            ? setIsTestDrive(true)
            : setIsTestDrive(false)
    };

    useEffect(() => {
        setIsExam(service[0].category.exam === 1 ? true : false)
        setIsTestDrive(service[0].category.test_drive === 1 ? true : false)
        serviceChangeHandler(props.initialValues.instructor_service_id)
    }, [])

    useEffect(() => {
        form.setFieldsValue({ city_id: cities[0].id })
    }, [serviceId])

    useEffect(() => {
        form.setFieldsValue({
            how_many_lessons: isExam ? 5 : isTestDrive ? 6 : 2,
        });
    }, [isExam, isTestDrive])

    React.useEffect(() => {
        decideIfToShowWarning(date, time, props.timeSlots);
    });

    const warning = <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={24}>
            <Alert
                message="Uzmanību"
                description="Šis laiks jau ir aizņemts!"
                type="warning"
                showIcon
            />
        </Col>
    </Row>

    const saveSlot = (values: any) => {
        if (disabled) {
            return;
        }
        else setDisabled(true)
        checkIfCanBeOptions(values.instructor_service_id, values);
        props.onAddSlot(values);
    }

    const checkIfCanBeOptions = (serviceID: any, values: any) => {
        const service = props.services.find((el: any) => el.id === serviceID);
        if (service && !!service?.category?.driving_options) return;
        delete values.driving_option;
    }

    return <Form
        name="add_slot_form"
        form={form}
        initialValues={props.initialValues}
        onFinish={saveSlot}
        layout="vertical"
    >
        <Form.Item name="instructor_service_id">
            <Select onChange={serviceChangeHandler}>
                {props.services.map((s: any) => (
                    <Select.Option value={s.id} key={`service-${s.id}`} title={`${s.category.value} kategorija (${s.vehicle_and_plate} ${s.transmission[0].toUpperCase()})`}>
                        {s.category.value} kategorija ({s.vehicle_and_plate} <b>{s.transmission[0].toUpperCase()}</b>)
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        {
            cities.length > 1
                ? <Form.Item initialValue={cities[0].id} name="city_id" rules={[{ required: true, message: "Obligāts lauks" }]}>
                    <Select placeholder="Pilsēta">
                        {cities.map((city: any) => {
                            return <Option key={city.id} value={city.id}>{city.value}</Option>
                        })}
                    </Select>
                </Form.Item>
                : <>
                    <Form.Item
                        style={{ display: "none" }}
                        name="city_id"
                        initialValue={cities[0].id}
                    >
                        <Input />
                    </Form.Item>
                    <div style={{ marginBottom: 24 }}>{cities[0].value}</div>
                </>
        }
        <Form.Item name="how_many_lessons">
            <Select placeholder="Nodarbības veids">
                {props.studentLessonKindsArray.filter((item: any, index: any) => {
                    return isExam ? index === 4 : isTestDrive ? index === 5 : index < 4;
                }).map((t: any, i: any, self: any) => {
                    const key = self.length > 1 ? i + 1 : isExam ? 5 : 6;
                    return <Select.Option key={`sfd-${key}`} value={key}>{t}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Row gutter={20}>
            <Col sm={8} xs={24}>
                <Form.Item label="Datums" name="date">
                    <DatePicker allowClear={false} onChange={onDateTimeChange('date')} />
                </Form.Item>
            </Col>
            <Col sm={8} xs={12}>
                <Form.Item label="Nodarbības sākums" name="time_from">
                    <TimePicker allowClear={false} onChange={onDateTimeChange('time')} minuteStep={15} format={timeFormat} />
                </Form.Item>
            </Col>
            <Col sm={8} xs={12}>
                <Form.Item label="Studentu skaits" name="how_many_students">
                    <Select defaultValue={1}>
                        <Select.Option value={1}>1</Select.Option>
                        <Select.Option value={2}>2</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Komentārs" name="comment">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
                {
                    !!service[0]?.category?.driving_options
                    && <Form.Item label="Braukšanas uzstādījumi" name="driving_option">
                        <Select >
                            <Select.Option value={""}>{""}</Select.Option>
                            {
                                Object.keys(drivingOptions).map((option: any, index: any) => {
                                    return <Select.Option key={index} value={option}>{drivingOptions[option]}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }
            </Col>
            {
                isExam
                    ? <></>
                    : <Col span={24}>
                        <b>Pievienot studentu:</b><br />
                        <Form.Item label="Studenta tālruņa nr." name="phone_number">
                            <Input style={{ width: 170 }} />
                        </Form.Item>
                    </Col>
            }
        </Row>
        {showWarning ? warning : null}
        {
            props.error && <Tag style={{ marginBottom: 8 }} color={"red"}>{props.error}</Tag>
        }
        <Form.Item>
            <Button htmlType="submit" type="primary">Pievienot</Button>
        </Form.Item>
    </Form>
}

export const CalendarPage = withRouter(BaseCalendarPage);

import { Button, Col, Row, Tag } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { StartEvaluationModal } from "../../StudentStats/components/StartEvaluationModal";
import { Link } from "react-router-dom";

export const NotApprovedSlot = (props: any) => {
    const { slot, updateTimeSlots, started } = props;
    const [visible, setVisible] = useState(false);

    return <li className="day-slot">
        <Row align="middle" gutter={16} justify="space-between">
            <Col>
                <Row align="middle">
                    <ExclamationCircleFilled style={{ color: "#DB4827", fontSize: 20 }} />
                    <Col style={{ paddingLeft: 10 }}>
                        <Link to={`student-stats/${slot?.student_id}`}><b>{slot?.student_name}</b></Link><br />
                        <small>{slot?.date}, {slot?.timer}</small>
                    </Col>
                    {
                        slot?.status === "test_drive"
                        && <Tag style={{ marginLeft: 20 }} color="purple">Testa brauciens</Tag>
                    }
                    {
                        slot?.status === "exam"
                        && <Tag style={{ marginLeft: 20 }} color="purple">Eksāmens</Tag>
                    }
                    {
                        (started && !(slot?.student_status === "done") && !(slot?.student_status === "exam_failed") && !(slot?.student_status === "exam_passed"))
                        && <Tag color="red">Uzsākta vērtēšana</Tag>
                    }
                </Row>
            </Col>
            <Col>
                <Button
                    className="btn-black"
                    style={{ marginLeft: 5 }}
                    type="primary"
                    onClick={() => setVisible(true)}
                >
                    Novērtēt
                </Button>
                {
                    visible
                    && <StartEvaluationModal
                        isVisible={visible}
                        setIsVisible={setVisible}
                        slot={slot}
                        updateTimeSlots={updateTimeSlots}
                        exam={slot?.student_status === "exam" || slot?.status === "exam"}
                        testDrive={slot?.status === "test_drive"}
                    />
                }
            </Col>
        </Row>
    </li>
}
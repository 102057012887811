import { Button, Card, Checkbox, Col, message, Modal, Row, Tag, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../../helpers/http";
import moment from "moment";
import { AxiosResponse } from "axios";
import { HowManyLessonsToOneContext } from "../../../helpers/HowManyLessonsToOneContext";

export const FinishEvalModal = (props: any) => {
    const {
        visible,
        setVisible,
        studentID,
        slotID,
        answers,
        studentLVL,
        mapPoints,
        feedback,
        exam,
        testDrive,
        examTheoryQuestion,
        figures,
        startTime
    } = props;
    const { howManyLessonsToOne } = useContext(HowManyLessonsToOneContext);
    const history = useHistory();
    const [canHaveExam, setCanHaveExam] = useState(false);
    const [slot, setSlot] = useState(null as any);
    const [studentStats, setStudentStats] = useState({} as any);
    const [sendToExam, setSendToExam] = useState(false);
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState([] as any);
    const [examStatus, setExamStatus] = useState(undefined as any);
    const [needRequestForExam, setNeedRequestForExam] = useState(true);
    const [studentInfo, setStudentInfo] = useState(null as any);
    const [sendToCsdd, setSendToCsdd] = useState(false);
    const [enoughPointsForExam, setEnoughPointsForExam] = useState(!!exam);
    const [failedExams, setFailedExams] = useState(0);
    const [sendToDriveOnlyErrors, setSendToDriveOnlyErrors] = useState(false);

    const getSlot = () => {
        setLoading(true);
        http.get(`lessons/pending/${slotID}`).then((res: any) => {
            console.log("SLOT:", res.data.data[0])
            if (res.data.data.length === 0) {
                history.goBack();
                message.error("Nodarbība vēl nav sākusies!")
                setVisible(false);
                return;
            }
            const studentSlot = res.data.data[0]
            setSlot(studentSlot)
            setCanHaveExam(
                studentSlot.hasOwnProperty('min_driving_lessons') || studentSlot.min_driving_lessons !== null
                    ? Number(studentSlot.lesson_count) + Number(studentSlot.how_many_lessons) >= Number(studentSlot.min_driving_lessons)
                    : false
            )
        })
    };

    const fetchStats = async (id: any) => {
        const data = {} as any;
        setLoading(true);
        // await http.get(`/student-progress/passed-lessons/${id}`, { params: { category: slot.category_id } }).then((response: any) => {
        //     data.passed = response.data;
        // });
        // await http.get(`/student-progress/passed-can-get-exam/${id}`, { params: { category: slot.category_id } }).then((response: any) => {
        //     data.canExam = response.data;
        // });
        await http.get(`/student-progress/has-exam/${id}`, { params: { category: slot.category_id } }).then((response: any) => {
            data.hasExam = response.data;
        });
        await http.get(`get-student-driving-exam-count/${studentID}/${slot.category_id}`).then((response: any) => {
            data.exam_count = response.data.data
        })
        setStudentStats(data);
    };

    const getDrivingGlobalSettings = () => {
        http.get(`global-settings-by-key/driving_exam_manual_request`, { params: { category: slot.category_id } }).then((response: any) => {
            console.log(response)
            const { data } = response.data;
            if (data !== null) {
                setNeedRequestForExam(!!Number(data.value))
            }
        });
    }

    const setSlotRouteTime = (lesson_id: number) => {
        const individual_route_start_time = startTime;
        const individual_route_end_time = startTime === slot?.timer.split(" - ")[0]
            ? slot?.timer.split(" - ")[1]
            : moment(slot?.timer.split(" - ")[1], "HH:mm:ss").isBefore(moment(), "minutes")
                ? slot?.timer.split(" - ")[1]
                : moment().format("HH:mm:ss")
        http.get(`get-driving-route/${lesson_id}`, {
            params: {
                individual_route_start_time,
                individual_route_end_time
            }
        })
    }

    const checkExamAllowedDependingOnPoints = () => {
        if (exam) return;
        http.post(`/predict-rating/${slotID}`, { answers }).then((res: AxiosResponse) => {
            console.log(res)
            setEnoughPointsForExam(res.data.allow);
        })
    }

    const submitEvalForm = () => {
        setLoading(true);
        if (sendToCsdd) {
            http.post(`student-progress/create-exam/${slot.student_id}`, { category: studentInfo.category_id })
                .then((res: AxiosResponse) => {
                    http.post(`/student-progress/send-to-csdd-exam/${slot.student_id}`, { category: studentInfo.category_id })
                })
        }
        if (sendToExam) {
            http.post(`/student-progress/create-exam/${slot.student_id}`, { category: slot.category_id }).then((response: any) => {
                console.log(response)
            });
        }
        setSlotRouteTime(slotID);
        http.post(`evaluate-driving-lesson/${slotID}`, { answers, level: studentLVL, failure_points: mapPoints, feedback, category: slot.category_id }).then((res: any) => {
            setVisible(false);
            history.goBack();
        })
    };

    const submitExamEvalForm = () => {
        http.post(`evaluate-exam-third-stage/${slotID}`, { answers, failure_points: mapPoints, feedback, category: slot.category_id }).then((res: any) => {
            console.log(res)
        })
    };

    const submitSecondExamStage = () => {
        http.post(`evaluate-exam-figures/${slotID}`, { figures, category: slot.category_id }).then((res: any) => {
            console.log(res)
        })
    };

    const submitFirstExamStage = () => {
        http.post(`evaluate-exam-first-stage/${slotID}/${examTheoryQuestion.question_id}`, { passed: examTheoryQuestion.passed, category: slot.category_id }).then((res: any) => {
            console.log(res)
        })
    };

    const triggerUpload = () => {
        document.getElementById('upload-protocol')?.click();
    };

    const saveImgData = (files: any) => {
        console.log(files)
        setImg(files);
    };


    const examPassed = () => {
        setExamStatus('exam_passed')
        setSendToDriveOnlyErrors(false);
        setSendToCsdd(false);
    };

    const examFailed = () => {
        setExamStatus('exam_failed')
    };

    const confirmTestDrive = () => {
        let data = new FormData();
        // data.append('feedback', feedback);

        // Array.from(img).map((item: any, index: any) => {
        //     if (index < 2) {
        //         data.append(`img${index === 0 ? "" : (index + 1)}`, img[index]);
        //     }
        // })
        // data.append('img', img[0])
        data.append('rating', "0");
        data.append('status', "done");
        data.append('category', slot?.category_id)

        http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, data).then((response: any) => {
            submitExamEvalForm();
            submitSecondExamStage();
            if (examTheoryQuestion) {
                submitFirstExamStage();
            }
            postExamResult(examStatus);
        })
    }

    const confirmExam = () => {
        setLoading(true);
        if (examStatus === undefined) {
            message.error('Lūdzu atzīmējiet eksāmena rezultātu')
            return;
        }

        // const { feedback, rating } = stats;
        let data = new FormData();
        // data.append('feedback', feedback);

        Array.from(img).map((item: any, index: any) => {
            if (index < 2) {
                data.append(`img${index === 0 ? "" : (index + 1)}`, img[index]);
            }
        })
        // data.append('img', img[0])
        data.append('rating', "0");
        data.append('status', examStatus);
        data.append('category', slot?.category_id)

        http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, data).then((response: any) => {
            submitExamEvalForm();
            submitSecondExamStage();
            if (examTheoryQuestion) {
                submitFirstExamStage();
            }
            postExamResult(examStatus);
        })
    }

    const postExamResult = async (result: string) => {
        if (result === "exam_failed") {
            if (sendToCsdd && !sendToDriveOnlyErrors) {
                http.post(`/student-progress/send-to-csdd-exam/${slot.student_id}`, { category: studentInfo.category_id })
            }
            await http.post(`/student-progress/failed-exam/${studentID}`,
                { category: slot.category_id, send_only_errors: sendToDriveOnlyErrors ? 1 : 0 })
                .then((response: any) => {
                    if (needRequestForExam && !sendToExam) {
                        history.goBack();
                        return;
                    };
                    http.post(`/student-progress/create-exam/${studentID}`, { category: slot.category_id }).then((response: any) => {
                        history.goBack();
                    });
                })
        } else if (result === "exam_passed") {
            await http.post(`/student-progress/passed-exam/${studentID}`, { category: slot.category_id }).then((response: any) => {
                console.log(response)
                history.goBack();
            })
        } else {
            history.goBack();
        }
    }

    const getStudentInfo = () => {
        http.get(`get-driving-lesson-csdd-allowance/${slot.student_lesson_id}`).then((res: any) => {
            console.log(res)
            setStudentInfo(res.data.data.length > 0 ? res.data.data[0] : null)
        });
        getStudentFailedExams();
    }

    const getStudentFailedExams = () => {
        http.get(`/failed-driving-exams/${studentID}/${slot.category_id}`).then((res: AxiosResponse) => {
            setFailedExams(res.data);
        })
    }

    useEffect(() => {
        getSlot();
    }, [])

    useEffect(() => {
        if (slot) {
            fetchStats(studentID);
            getDrivingGlobalSettings();
            getStudentInfo();
            // console.log(startTime, slot.timer.split(" - "))
            // const individual_route_start_time = startTime;
            // const individual_route_end_time = startTime === slot?.timer.split(" - ")[0]
            //     ? slot?.timer.split(" - ")[1]
            //     : moment(slot?.timer.split(" - ")[1], "HH:mm:ss").isBefore(moment(), "minutes")
            //         ? slot?.timer.split(" - ")[1]
            //         : moment().format("HH:mm:ss")
            //         console.log("start_time ", individual_route_start_time, " end_time", individual_route_end_time)
        }
    }, [slot]);

    useEffect(() => {
        if (Object.keys(studentStats).length > 0) {
            setLoading(false)
        }
    }, [studentStats])

    useEffect(() => {
        if (visible) {
            checkExamAllowedDependingOnPoints();
        } else {
            setEnoughPointsForExam(false);
        }
    }, [visible])

    return <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        // onOk={exam ? confirmExam : testDrive ? confirmTestDrive : submitEvalForm}
        footer={<Row justify="end">
            <Button
                onClick={() => setVisible(false)}
            >
                Atcelt
            </Button>
            <Button
                type="primary"
                onClick={exam ? confirmExam : testDrive ? confirmTestDrive : submitEvalForm}
                loading={loading}
            >
                Apstiprināt
            </Button>
        </Row>}
    >
        <Card
            loading={loading}
        >
            <div>
                <p>Pabeigt nodarbību studentam <b>{slot?.student_name}?</b></p>
                {!testDrive && <p>Nobrauktās mācību stundas: <b>{Number(slot?.lesson_count) + (howManyLessonsToOne.some((count: Number) => count === Number(slot?.how_many_lessons)) ? 0 : Number(slot?.how_many_lessons))}</b></p>}
                {
                    studentStats?.exam_count > 0
                    && <p><b>Kārtoti eksāmeni: {studentStats?.exam_count}</b></p>
                }
                {
                    exam
                    && <Row gutter={24} style={{ marginBottom: 10 }}>
                        <Col span={12}>
                            <Button icon={<UploadOutlined />} onClick={triggerUpload}>Pievienot failus</Button>
                            <input style={{ display: "none" }} id="upload-protocol" name="img" type="file" onChange={(e: any) => saveImgData(e.target.files)} multiple />
                        </Col>
                        {
                            img.length > 0
                                ? <Col> {Array.from(img).map((img: any, index: any) => {
                                    if (index < 2) {
                                        return <p>{img.name}</p>
                                    }
                                    return <><Typography.Text type="danger">Maksimālais failu daudzums: 2</Typography.Text><br /></>
                                })}</Col>
                                : <></>
                        }
                        <Col span={24}>
                            <Row gutter={16} style={{ marginTop: 20 }}>
                                <Col>
                                    <Button onClick={examPassed} className="btn-green" type="primary">Nokārtots</Button>
                                </Col>
                                <Col>
                                    <Button onClick={examFailed} type="primary" danger>Nav nokārtots</Button>
                                </Col>
                            </Row>
                        </Col>
                        {examStatus === "exam_passed"
                            ? <Col span={24} style={{ paddingTop: 10 }}>
                                <Tag color="green">Nokārtots</Tag>
                            </Col>
                            : examStatus === "exam_failed"
                                ? <Col span={24} style={{ paddingTop: 10 }}>
                                    <Tag color="red" style={{ marginBottom: 10 }}>Nav nokārtots</Tag>
                                    {
                                        studentStats?.exam_count >= 2
                                        && <>
                                            <div style={{ marginBottom: 10 }}>
                                                <Checkbox
                                                    style={{ marginRight: 10 }}
                                                    onClick={(e: any) => {
                                                        setSendToDriveOnlyErrors(e.target.checked)
                                                        setSendToCsdd(false)
                                                    }}
                                                    checked={sendToDriveOnlyErrors && !sendToCsdd}
                                                />
                                                <b>Novirzīt izbraukt kļūdas pie instruktora, kurš novirzīs pēc tam uz CSDD</b>
                                            </div>
                                            {/* <div>
                                                <Checkbox
                                                    style={{ marginRight: 10 }}
                                                    onClick={(e: any) => {
                                                        setSendToCsdd(e.target.checked)
                                                        setSendToDriveOnlyErrors(false)
                                                    }}
                                                    checked={sendToCsdd && !sendToDriveOnlyErrors}
                                                />
                                                <b>Novirzīt uz CSDD braukšanas eksāmenu</b>
                                            </div> */}
                                        </>
                                    }


                                    {/* <Button
                                        type="primary"
                                        onClick={() => setExamStatus(undefined)}
                                        style={{ marginBottom: 10 }}
                                    >
                                        Novirzīt mācību braukšanai
                                    </Button> */}
                                </Col>
                                : <></>}
                    </Row>
                }
                {
                    (
                        canHaveExam
                        && !!!studentStats.hasExam?.active
                        // && studentStats?.exam_count < 3
                        && !studentStats?.hasExam?.passed
                        && !sendToExam
                        && enoughPointsForExam
                    ) && !testDrive
                        ? <Button
                            type="primary"
                            onClick={() => setSendToExam(true)}
                        >
                            Novirzīt skolas vadīšanas eksāmenam
                        </Button>
                        : sendToExam
                            ? <b>Students ir novirzīts skolas vadīšanas eksāmenam!</b>
                            : <></>
                }
                {
                    (!!studentInfo?.allow_send_to_csdd_without_school_exam
                        && Number(studentInfo?.driven_lessons) >= studentInfo?.min_driving_lessons
                        && !!!studentInfo?.sent_to_csdd_driving
                        && failedExams >= 2)
                    && <Col span={24} style={{ paddingBottom: 20 }}>
                        <Checkbox
                            style={{ marginRight: 10 }}
                            onClick={(e: any) => setSendToCsdd(e.target.checked)}
                        />
                        <b>Novirzīt uz CSDD braukšanas eksāmenu</b>
                    </Col>
                }
                {
                    !!studentInfo?.sent_to_csdd_driving
                    && <Tag style={{ marginBottom: 20 }} color="green">Students novirzīts CSDD eksāmenam</Tag>
                }
            </div>
        </Card>
    </Modal>
}
import { Anchor, Badge, Button, Card, Col, Divider, Empty, Input, Layout, message, Modal, Rate, Row, Skeleton, Space, Tag, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { http } from "../helpers/http";
import moment from 'moment';
import { AxiosResponse } from "axios";
import { HowManyLessonsToOneContext } from '../helpers/HowManyLessonsToOneContext';

moment.updateLocale('lv', {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    week: {
        dow: 1
    },
    months: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris']
});

const { Text } = Typography;

const ExaminedStudents = (props: any) => {
    const { exam, refresh } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [noImage, setNoImage] = useState(true);
    const [hasSecondImg, setHasSecondImg] = useState(false);
    const [sentToCsdd, setSentToCsdd] = useState(
        {
            status: false,
            changed: false,
        }
    );
    const [allowedExtraLessons, setAllowExtraLessons] = useState(
        {
            status: false,
            changed: false,
        }
    );
    const [loaded, setLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [failedExams, setFailedExams] = useState(0);

    // useEffect(() => {
    //    console.log(sentToCsdd, allowedExtraLessons)
    // }, [sentToCsdd, allowedExtraLessons])

    // const fileName = exam.attachment.split("/")[exam.attachment.split("/").length - 1];
    // const fileName2 = exam.attachment2?.split("/")[exam.attachment.split("/").length - 1];
    const filePath = process.env.REACT_APP_API_URL + "storage/student_lesson_reports/" + exam.attachment;
    const filePath2 = process.env.REACT_APP_API_URL + "storage/student_lesson_reports/" + exam.attachment2;

    const getStudentFailedExams = () => {
        http.get(`/failed-driving-exams/${exam?.student_id}/${exam?.category_id}`).then((res: AxiosResponse) => {
            setFailedExams(res.data);
        })
    }

    useEffect(() => {
        if (modalVisible) {
            getStudentFailedExams();
        }
        setSentToCsdd(
            {
                status: exam.sent_csdd ? true : false,
                changed: false,
            });
        setAllowExtraLessons(
            {
                status: exam.extra_lessons ? true : false,
                changed: false,
            });
    }, [modalVisible]);

    useEffect(() => {
        if (!filePath.includes('noimage') && !filePath.includes('null')) {
            setNoImage(false)
        }
        if (!filePath2.includes('noimage') && !filePath2.includes('null')) {
            setHasSecondImg(true);
        }
    }, [filePath, filePath2]);

    const sendToCsdd = () => {
        setSentToCsdd(
            {
                status: true,
                changed: true,
            }
        );

    }

    const allowExtraLessons = () => {
        setAllowExtraLessons(
            {
                status: true,
                changed: true,
            }
        );
    }

    const saveChoice = async () => {
        setLoading(true);
        if (allowedExtraLessons.status && allowedExtraLessons.changed) {
            http.post(`/student-progress/extra-lessons/${exam.student_id}`).then((response: any) => {
                // refresh()
            })
        }
        if (sentToCsdd.status && sentToCsdd.changed) {
            await http.post(`/student-progress/send-to-csdd-exam/${exam.student_id}`, { category: exam.category_id }).then((response: any) => {
                refresh()
            })
        }
        setModalVisible(false);
    }

    return (
        <>
            <Card style={{ width: "100%", backgroundColor: exam.status === "exam_passed" ? "rgb(144 238 144)" : "#ffccc7" }}>
                <Row>
                    <Col lg={4} span={24}>
                        {exam.first_name} {exam.last_name}
                    </Col>
                    <Col lg={4} span={12}>
                        {exam.date}
                    </Col>
                    <Col lg={4} span={12}>
                        {exam.time_from} - {exam.time_to}
                    </Col>
                    <Col lg={4} span={12}>
                        {exam.category_name}
                    </Col>
                    <Col lg={3} span={12}>
                        {exam.city}
                    </Col>
                    <Col lg={3} span={12}>
                        {exam.status === "exam_failed" ? "Nav nokārtots" : "Nokārtots"}
                    </Col>
                    <Col lg={2} span={12}>
                        <Button onClick={() => setModalVisible(true)}>Darbības</Button>
                    </Col>
                </Row>
                <Modal
                    title={"Darbības pēc eksāmena: " + exam.first_name + " " + exam.last_name}
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    onOk={saveChoice}
                    footer={[
                        <Button key="back" onClick={() => setModalVisible(false)}>
                            Aizvērt
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={saveChoice}>
                            Apstiprināt
                        </Button>,
                    ]}
                >
                    <Text>Status: {exam.status !== "exam_failed" ? "Nokārtots" : "Nav nokārtots"}</Text><br />
                    <Text>Kārtoti eksāmeni: {exam.exam_counter}</Text><br />
                    {exam.status === "exam_passed"
                        ? <></>
                        : <>
                            Tālruņa nr. <a href={`tel:${exam.phone_number}`}>{exam.phone_number}</a>
                        </>}
                    <Divider />
                    <Row justify="space-between" style={{ margin: 10 }}>
                        {
                            noImage
                                ? "Nav protokola"
                                : hasSecondImg
                                    ? <>
                                        <a href={filePath} target="_blank" download><Button style={{ width: "100%" }}>Nodarbības protokols-1</Button></a>
                                        <a href={filePath2} target="_blank" download><Button style={{ width: "100%" }}>Nodarbības protokols-2</Button></a>
                                    </>
                                    : <a href={filePath} target="_blank" download><Button style={{ width: "100%" }}>Nodarbības protokols</Button></a>
                        }
                    </Row>
                    {/* {
                        exam.status === "exam_passed" ? <></>
                            : <Row justify="space-between" style={{ margin: 10 }}>
                                <Divider />
                                {
                                    allowedExtraLessons.status && loaded
                                        ? <Tag color="green">Studentam ir atļautas papildus braukšanas nodarbības</Tag>
                                        : <Button type="primary" onClick={allowExtraLessons}>Atļāut papildus braukšanas nodarbības</Button>
                                }
                            </Row>
                    } */}
                    {
                        !!exam.got_licence
                            ? <Tag color="green">Students ir saņēmis vadītāja apliecību</Tag>
                            : sentToCsdd.status && loaded
                                ? <Tag style={{ marginLeft: 10 }} color="green">Students novirzīts CSDD eksāmenam</Tag>
                                : failedExams > 2
                                    ? <Button style={{ marginLeft: 10 }} className="btn-green" type="primary" onClick={sendToCsdd}>Novirzīt uz CSDD eksāmenu</Button>
                                    : <></>
                    }
                </Modal>
            </Card>
        </>
    )
}

const ApprovalLessonCard = (props: any) => {
    const { slot, needRequestForExam } = props;

    const [openOkModal, setOpenOkModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [stats, setStats] = useState({} as any);
    const [studentStats, setStudentStats] = useState({} as any);
    const [loaded, setLoaded] = useState(false);
    const [hasExam, setHasExam] = useState(false);
    const [img, setImg] = useState([] as any);
    const [examLesson, setIsExamLesson] = useState(slot?.exam);
    const [examStatus, setExamStatus] = useState(undefined as any);
    const [endLearning, setEndLearning] = useState(false);
    const [learningEnded, setLearningEnded] = useState(false);
    // console.log(slot, examLesson);
    // const cityService = slot.instructor_service.city.find((el: any) => el.city_id === slot.city_id);
    // const cityObj = slot.instructor_service.cities.filter((city: any) => {
    //     return city.id === cityService?.city_id;
    // })[0];
    // const studentLesson = slot.student_lessons[0];
    // const name = `${studentLesson.student.first_name} ${studentLesson.student.last_name}`;
    // const date = moment(slot.date);
    // const time_from = moment(slot.time_from, "HH:mm:ss").format("HH:mm");
    // const time_to = moment(slot.time_to, "HH:mm:ss").format("HH:mm");
    // const lesson_count = studentStats.passed + (slot.how_many_lessons < 5 ? slot.how_many_lessons : 0);
    const can_exam = slot.hasOwnProperty('min_driving_lessons') || slot.min_driving_lessons !== null
        ? (Number(slot.lesson_count) + Number(slot.how_many_lessons) >= Number(slot.min_driving_lessons)) && slot.category_id === 1
        : false;
    const canEndDriving = slot.hasOwnProperty('min_driving_lessons') || slot.min_driving_lessons !== null
        ? Number(slot.lesson_count) + Number(slot.how_many_lessons) >= Number(slot.min_driving_lessons)
        : false;

    const instructorsTesters = [5, 16, 48, 66, 90, 15, 4, 89, 26, 2];
    const studentExamCount = props.exams.filter((exam: any) => {
        return exam.student_id === slot.student_id;
    }).length;

    useEffect(() => {
        if (openOkModal) fetchStats(slot.student_id);
    }, [openOkModal])

    useEffect(() => {
        if (Object.keys(studentStats).length > 0) setLoaded(true);
        // console.log(studentStats, hasExam)
    }, [studentStats])

    const openModalHandler = () => {
        setOpenOkModal(true);
    }

    const closeModal = () => {
        setOpenOkModal(false);
    }

    const triggerUpload = () => {
        document.getElementById('upload-protocol')?.click();
    }

    const confirm = () => {
        if (examLesson && examStatus === undefined) {
            message.error('Lūdzu atzīmējiet eksāmena rezultātu')
            return;
        }

        const { feedback, rating } = stats;
        let data = new FormData();
        data.append('feedback', feedback);

        if (hasExam && !examLesson) {
            http.post(`/student-progress/create-exam/${slot.student_id}`, { category: slot.category_id }).then((response: any) => {
                console.log(response)
            });
        }

        if (endLearning) {
            http.get(`/student-ended-learning/${slot.student_id}/${slot.category_id}?change=1&value=1`).then((response: any) => {
                console.log(response)
            })
        }

        if (examLesson) {
            Array.from(img).map((item: any, index: any) => {
                if (index < 2) {
                    data.append(`img${index === 0 ? "" : (index + 1)}`, img[index]);
                }
            })
            // data.append('img', img[0])
            data.append('rating', "0");
            data.append('status', examStatus);
        } else {
            data.append('status', 'done');
            data.append('rating', rating);
        }

        console.log(data)
        http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, data).then((response: any) => {
            console.log(response)
            postExamResult(examStatus);
        })
    }

    const postExamResult = async (result: string) => {
        if (result === "exam_failed") {
            await http.post(`/student-progress/failed-exam/${slot.student_id}`, { category: slot.category_id }).then((response: any) => {
                console.log(response)
                if (needRequestForExam) {
                    window.location.reload();
                    return;
                };
                http.post(`/student-progress/create-exam/${slot.student_id}`, { category: slot.category_id }).then((response: any) => {
                    console.log(response);
                    window.location.reload();
                });
            })
        } else if (result === "exam_passed") {
            await http.post(`/student-progress/passed-exam/${slot.student_id}`, { category: slot.category_id }).then((response: any) => {
                console.log(response)
                window.location.reload();
            })
        } else {
            window.location.reload();
        }
    }

    const changeFeedback = (e: any) => {
        setStats((old: any) => {
            return {
                ...old,
                feedback: e.target.value,
            }
        })
    }

    const changeRating = (rating: any) => {
        setStats((old: any) => {
            return {
                ...old,
                rating: rating,
            }
        })
    }

    const openCancelModalHandler = () => {
        setOpenCancelModal(true);
    }

    const closeCancelModal = () => {
        setOpenCancelModal(false);
    }

    const saveImgData = (files: any) => {
        console.log(files)
        setImg(files);
    }

    const fetchStats = async (id: any) => {
        const data = {} as any;
        // await http.get(`/student-progress/passed-lessons/${id}`).then((response: any) => {
        //     data.passed = response.data;
        // });
        // await http.get(`/student-progress/passed-can-get-exam/${id}`).then((response: any) => {
        //     data.canExam = response.data;
        // });
        await http.get(`/student-progress/has-exam/${id}`).then((response: any) => {
            data.hasExam = response.data;
        });
        await http.get(`/student-ended-learning/${id}/${slot.category_id}`).then((res: any) => {
            setLearningEnded(!!res.data)
        })
        setStudentStats(data);
        setHasExam(data.hasExam?.active === 1 ? true : false)
    }

    const cancel = () => {
        http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, {
            status: "student_didnt_come"
        }).then((response: any) => {
            console.log(response);
            window.location.reload();
        });
    }

    const toExam = () => {
        setHasExam(true);
    }

    const endLearningHandler = () => {
        setEndLearning(!endLearning);
    }

    const denyExam = () => {
        http.post(`/student-progress/remove-exam/${slot.student_id}`).then((response: any) => {
            console.log(response)
            setHasExam(false);
        });
    }

    const examPassed = () => {
        setExamStatus('exam_passed')
    }

    const examFailed = () => {
        setExamStatus('exam_failed')
    }

    return (
        <Card key={`slot-lesson-pending-${slot.slot_id}`} style={{ marginBottom: 5 }}>
            <Row gutter={[20, 10]}>
                <Col xs={{ span: 24 }} md={{ span: 5 }}>
                    <Badge
                        status="default"
                        text={<strong>{slot.student_name}&nbsp;
                            {
                                instructorsTesters.some((el: any) => el === props.self?.id)
                                    ? <Link to={`/valuate-student/${slot.student_id}`}
                                    >
                                        Vērtēt
                                    </Link>
                                    : <></>
                            }
                        </strong>}
                    />
                </Col>
                {
                    !!slot.exam
                        ? <></>
                        : <Col xs={{ span: 12 }} md={{ span: 1 }}>
                            <b>{Number(slot.lesson_count) + slot.how_many_lessons}</b>
                        </Col>
                }
                <Col xs={{ span: 12 }} md={{ span: 2 }}>{slot.date}</Col>
                <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.timer}</Col>
                <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.category} kategorija</Col>
                <Col xs={{ span: 12 }} md={{ span: 5 }}>{slot.meeting_place} {slot.slot_city}</Col>
                <Col xs={{ span: 24 }} md={{ span: 5 }}>
                    <Space style={{ float: "right" }}>
                        <Button onClick={openModalHandler} type="primary">Notika</Button>
                        <Button onClick={openCancelModalHandler} danger type="primary">Nenotika</Button>
                    </Space>
                </Col>
            </Row>
            <Modal onOk={cancel} onCancel={closeCancelModal} visible={openCancelModal}>
                Students neieradās?
            </Modal>
            <Modal onOk={confirm} onCancel={closeModal} title="Sniegt atsauksmi par nodarbību" visible={openOkModal}>
                {!loaded
                    ? <Skeleton />
                    : <>
                        <Row gutter={20} style={{ paddingBottom: 20, marginBottom: 20, borderBottom: "1px solid #f0f0f0" }}>
                            <Col span={8}>
                                <strong>{slot.student_name}</strong>
                            </Col>
                            <Col span={8}>
                                {slot.date}
                            </Col>
                            <Col span={8}>
                                {slot.timer}
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ paddingBottom: 20, marginBottom: 20, borderBottom: "1px solid #f0f0f0" }}>
                            {slot.status === "new"
                                ? <>
                                    <Col span={12}>
                                        Novērtējiet studenta progresu
                                    </Col>
                                    <Col span={12}>
                                        <Rate value={stats.rating} onChange={changeRating} />
                                    </Col>
                                </>
                                : <Row gutter={[8, 8]}>
                                    <Col>
                                        Eksāmens:
                                    </Col>
                                    <Col>
                                        <Button onClick={examPassed} className="btn-green" type="primary">Nokārtots</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={examFailed} type="primary" danger>Nav nokārtots</Button>
                                    </Col>
                                    <Col>
                                        <Button icon={<UploadOutlined />} onClick={triggerUpload}>Protokols</Button>
                                        <input style={{ display: "none" }} id="upload-protocol" name="img" type="file" onChange={(e: any) => saveImgData(e.target.files)} multiple />
                                    </Col>
                                    {
                                        img.length > 0
                                            ? <Col> {Array.from(img).map((img: any, index: any) => {
                                                if (index < 2) {
                                                    return <p>{img.name}</p>
                                                }
                                                return <><Text type="danger">Maksimālais failu daudzums: 2</Text><br /></>
                                            })}</Col>
                                            : <></>
                                    }
                                    {examStatus === "exam_passed"
                                        ? <Col span={24}>
                                            <Tag color="green">Nokārtots</Tag>
                                        </Col>
                                        : examStatus === "exam_failed"
                                            ? <Col span={24}>
                                                <Tag color="red">Nav nokārtots</Tag>
                                            </Col>
                                            : <></>}
                                </Row>}
                        </Row>
                        <Row gutter={20} style={{ paddingBottom: 10 }}>
                            <Col span={24}>
                                Pamatojiet savu vērtējumu
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Input.TextArea value={stats.feedback} onChange={changeFeedback} rows={3} />
                            </Col>
                        </Row>
                        <Row gutter={20} justify="space-between">
                            {/* <Button onClick={() => console.log(slot)}>CHECK</Button> */}
                            {
                                slot.status === "new"
                                    ? <Col style={{ padding: 15 }}>
                                        <Text>Mācību stundas: {Number(slot.lesson_count) + Number(slot.how_many_lessons)}</Text><br />
                                        <Text>Kārtotie eksāmeni: {studentExamCount}</Text>
                                    </Col>
                                    : <></>
                            }
                            <Col style={{ padding: 15 }}>
                                {/* {
                                    can_exam && !studentStats.hasExam.passed && !studentStats.hasExam.failed
                                        ? studentStats.canExam && hasExam
                                            ? "Students ir pieteikts eksāmenam"
                                            : <Button onClick={toExam} type="primary">Novirzīt skolas vadīšanas eksāmenam</Button>
                                        : <></>
                                } */}
                                {
                                    can_exam && !hasExam && studentExamCount < 3 && !studentStats.hasExam.passed
                                        ? <Button onClick={toExam} type="primary">Novirzīt skolas vadīšanas eksāmenam</Button>
                                        : !can_exam || studentStats.hasExam.passed || studentExamCount >= 3
                                            ? canEndDriving
                                                ? learningEnded
                                                    ? <Tag color="green">Apmācība ir noslēgta</Tag>
                                                    : endLearning
                                                        ? <>
                                                            <Tag color="green">Apmācība ir noslēgta</Tag>
                                                            <Button
                                                                style={{ marginLeft: 8 }}
                                                                onClick={endLearningHandler}
                                                            >
                                                                Atcelt
                                                            </Button>
                                                        </>
                                                        : <Button onClick={endLearningHandler} type="primary">Noslēgt apmācību</Button>
                                                : <></>
                                            : "Students ir pieteikts eksāmenam"
                                }
                            </Col>
                        </Row>
                    </>}
            </Modal>
        </Card>
    );
}

export type DashboardPageProps = RouteComponentProps & {
    self: any
};

export type DashboardPageState = {
    pendingApproval: any,
    pendingApprovalLoaded: boolean,
    // examSlots: [],
    // examLessons: [],
    // examined: [],
    exams: any,
    examsLoading: boolean,
    upcoming: any,
    upcomingLoaded: boolean,
    cancelUpcoming: any,
    isExam: boolean,
    completed: boolean,
    loading: boolean,
    timeSlots: any,
    needRequestForExam: boolean,
    self: any;
    cancelComment: string;
};

export class BaseDashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
    constructor(props: DashboardPageProps) {
        super(props);

        this.state = {
            pendingApproval: [],
            pendingApprovalLoaded: false,
            // examSlots: [],
            // examLessons: [],
            // examined: [],
            exams: [] as any,
            examsLoading: true,
            upcoming: [],
            upcomingLoaded: false,
            cancelUpcoming: null,
            isExam: false,
            completed: false,
            loading: false,
            timeSlots: [],
            needRequestForExam: true,
            self: {},
            cancelComment: ""
        };
    }
    HowManyLessonsToOne = [5, 6]
    instructorsTesters = [5, 16, 48, 66, 90, 15, 4, 89, 26, 2];

    async componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.cancelUpcoming !== this.state.cancelUpcoming) {
            if (this.state.cancelUpcoming === null) {
                this.setState({ loading: false });
            }
        }
        if (prevState.examsLoading !== this.state.examsLoading) {
        }
    }

    async fetch() {
        (window as any)['http'] = http;
        http.get(`global-settings-by-key/driving_exam_manual_request`).then((response: any) => {
            console.log(response)
            const { data } = response.data;
            if (data !== null) {
                this.setState({
                    needRequestForExam: !!Number(data.value)
                })
            }
        });
        // http.get('self').then((response: any) => {
        //     this.setState({ self: response.data.data })
        // })
        const pendingApproval = (await http.get('lessons/pending')).data.data;
        const upcoming = (await http.get('lessons/upcoming')).data.data;
        const timeSlots = await http.get('time_slots', {
            params: {
                instructor_exam_students: 1,
                new: 1
            }
        });
        if (timeSlots.data.data.length > 0) {
            this.getExamSlots(timeSlots.data.data);

        } else {
            this.setState({ examsLoading: false })
        }
        this.setState({
            pendingApproval,
            pendingApprovalLoaded: true,
            upcoming,
            upcomingLoaded: true,
            timeSlots: timeSlots.data.data
        });
    }

    getExamSlots = async (slots: any) => {
        const exams = slots.map((slot: any) => {
            return slot.student_id;
        });
        const serviceIDs = this.props.self?.services?.filter((service: any) => {
            return !service.category.exam
        }).map((service: any) => {
            return service.id
        });
        const categories = slots.map((slot: any) => {
            return slot?.student.category.id;
        }).filter((id: any, index: any, self: any) => {
            return self.indexOf(id) === index && id !== undefined;
        })
        if (categories.length > 0) {
            this.setState({ exams: [] });
            await categories.map(async (catID: any) => {
                // console.log(exams)
                await http.post(`/student-progress/bulk-driven-exams/${catID}`, { ids: exams, service_ids: serviceIDs }).then((response: any) => {
                    if (response.data.length > 0) {
                        const exams = response.data[0].map((slot: any) => {
                            return {
                                ...slot,
                                exam_counter: response.data[1].exam_counts[slot.student_id]
                            }
                        })
                        this.setState({ exams: this.state.exams.concat(exams) })
                    }
                })
            })
            this.setState({ examsLoading: false });
        }
    }

    renderPendingLessons() {
        if (!this.state.pendingApprovalLoaded) {
            return <Skeleton />
        }

        if (this.state.pendingApproval.length === 0) {
            return <Empty />
        }

        return (this.state.pendingApproval.map((slot: any) => {
            return <ApprovalLessonCard
                exams={this.state.exams}
                key={slot.slot_id}
                slot={slot}
                refresh={this.fetch}
                self={this.props.self}
                needRequestForExam={this.state.needRequestForExam}
            />
        }))
    }

    renderUpcomingLessons() {

        if (!this.state.upcomingLoaded) {
            return <Skeleton />
        }

        if (this.state.upcoming.length === 0) {
            return <Empty />
        }

        return (
            <>
                {this.state.upcoming.map((slot: any) => {
                    const startTime = slot?.date + " " + slot?.timer.split(" ")[0];
                    const cancelWithoutPenalty = moment().add(48, "hours").isSameOrBefore(startTime);

                    const openCancelModal = () => {
                        this.setState({ cancelUpcoming: slot.slot_id });
                    }

                    const closeCancelModal = () => {
                        this.setState({ cancelUpcoming: null, cancelComment: "" });
                    }

                    const cancel = async () => {
                        // http.patch(`lessons/${this.state.cancelUpcoming}`, { comment: "" }).then((response: any) => {
                        //     this.setState({
                        //         cancelUpcoming: null,
                        //         upcoming: this.state.upcoming.filter((s: any) => s.id !== slot.slot_id)
                        //     });
                        // });
                        // http.post(`lessons/submit_feedback/${this.state.cancelUpcoming}`, {
                        //     status: "instructor_cancelled"
                        // }).then(() => {
                        //     this.setState({
                        //         cancelUpcoming: null,
                        //         upcoming: this.state.upcoming.filter((s: any) => s.id !== slot.slot_id)
                        //     });

                        // });
                        if (this.state.cancelComment === "") {
                            message.error("Ievadiet iemeslu");
                            return;
                        }
                        this.setState({ loading: true });
                        http.post(`time_slots/${this.state.cancelUpcoming}/remove_student`, {
                            instructor_cancel_comment: this.state.cancelComment
                        }).then((response: any) => {
                            this.setState({
                                cancelComment: "",
                                cancelUpcoming: null,
                                upcoming: this.state.upcoming.filter((s: any) => s.slot_id !== slot.slot_id)
                            });
                        })
                    }

                    return <Card loading={this.state.loading} key={`upc-${slot.slot_id}`} style={{ marginBottom: 5 }}>
                        <Row gutter={[20, 10]}>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <Badge
                                    status="default"
                                    text={<strong>{slot.student_name}&nbsp;
                                        {/* {
                                            this.instructorsTesters.some((el: any) => el === this.props.self?.id)
                                                ? <Link to={`/valuate-student/${slot.student_id}`}
                                                >
                                                    Vērtēt
                                                </Link>
                                                : <></>
                                        } */}
                                    </strong>}
                                />
                            </Col>
                            {
                                !!slot.exam
                                    ? <></>
                                    : <Col xs={{ span: 12 }} md={{ span: 1 }}>
                                        <b>{Number(slot.lesson_count) + (this.HowManyLessonsToOne.some((num: number) => num === slot.how_many_lessons) ? 1 : slot.how_many_lessons)}</b>
                                    </Col>
                            }
                            <Col xs={{ span: 12 }} md={{ span: 2 }}>{slot.date}</Col>
                            <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.timer}</Col>
                            <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.category} kategorija</Col>
                            <Col xs={{ span: 12 }} md={{ span: 5 }}>{slot.slot_city}, {slot.meeting_place}</Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <Space style={{ float: "right" }}>
                                    {
                                        (!!slot.allow_bought_slot_del || window.sessionStorage.getItem('admin'))
                                        && <Button onClick={openCancelModal} danger type="primary">Atcelt</Button>
                                    }
                                    <Modal
                                        onOk={cancel}
                                        onCancel={closeCancelModal}
                                        visible={this.state.cancelUpcoming === slot.slot_id}
                                    >
                                        {/* {
                                            cancelWithoutPenalty
                                            || <Tag color="error">
                                                Brīdinājums par atcelšanu
                                            </Tag>
                                        } */}
                                        <p>Vai atcelt nodarbību?</p>
                                        <Input.TextArea
                                            value={this.state.cancelComment}
                                            onChange={(e) => this.setState({ cancelComment: e.target.value })}
                                            placeholder="Ievadiet iemeslu"
                                        />
                                    </Modal>
                                </Space>
                            </Col>
                        </Row>
                    </Card>;
                })}
            </>
        );
    }

    render() {
        return (
            <Layout className="main-content">
                <Content style={{ padding: 15 }}>
                    {/* <Row gutter={[20, 10]} >
                        <Button onClick={() => console.log(this.state.examined)}>!</Button>
                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                            <Card style={{ borderTop: "2px solid #0094c4" }}>
                                <Statistic title="Uzkrāts Einšteins depozītkontā" value={2749} />
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                            <Card style={{ borderTop: "2px solid #0094c4" }}>
                                <Statistic title="Depozīta izmaksas datums" value="24/01" />
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                            <Card style={{ borderTop: "2px solid #0094c4" }}>
                                <Statistic title="Veiktas nodarbības decembrī" value={20} />
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                            <Card style={{ borderTop: "2px solid #0094c4" }}>
                                <Statistic title="Aktīvi studenti" value={7} />
                            </Card>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col span={24}>
                            <Card
                                loading={this.state.examsLoading}
                                title="Studenti, kuri ir kārtojuši eksāmenu"
                                style={{ width: "100%", marginBottom: 20 }}
                            >
                                <div style={{ maxHeight: 300, overflowY: "scroll" }}>
                                    {
                                        this.state.exams.map((slot: any, index: any) => {
                                            return <ExaminedStudents
                                                key={index}
                                                refresh={() => {
                                                    this.setState({ examsLoading: true });
                                                    this.fetch()
                                                }}
                                                exam={slot}
                                            />
                                        })
                                    }
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Row gutter={20} style={{ marginBottom: 15 }}>
                        <Col span={24}>
                            <Card title={<Space><span style={{ whiteSpace: "normal" }}>Nodarbības, kurām nepieciešams apstiprinājums</span></Space>}>
                                {this.renderPendingLessons()}
                            </Card>
                        </Col>
                    </Row> */}

                    <Row gutter={20} style={{ marginBottom: 15 }}>
                        <Col span={24}>
                            <Card title={<Space><span>Tuvākās nodarbības</span></Space>}>
                                {this.renderUpcomingLessons()}
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export const DashboardPage = withRouter(BaseDashboardPage);
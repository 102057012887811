import { AutoComplete, Badge, Button, Card, Col, DatePicker, Empty, Input, Layout, Popover, Rate, Row, Skeleton, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Link, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { http } from "../helpers/http";
import moment from 'moment';
import { InfoCircleOutlined } from "@ant-design/icons";
import { AxiosResponse } from 'axios';
import { StaticContext, Switch } from "react-router";
import { LessonInfo } from "./LessonInfo";

moment.updateLocale('lv', {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    week: {
        dow: 1
    },
    months: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris']
});

function onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

export type HistoryPageProps = RouteComponentProps & {
};

export type HistoryPageState = {
    loaded: boolean,
    lessonHistoryByMonth: {},
    lessonHistoryFiltered: {},
    prevFilterState: {},
    options: [{}],
    filterValue: string,
    dateFilterValue: null,
    filters: {
        date: null,
        name: null
    },
    newFilters: Filters,
    subjects: any,
    examSubjects: any
};

type Filters = {
    params: {
        start_date?: string;
        end_date?: string;
        student?: string;
        phone?: string;
    }
}

export class BaseHistoryPage extends React.Component<HistoryPageProps, HistoryPageState> {
    state = {
        loaded: false,
        lessonHistoryByMonth: {} as any,
        lessonHistoryFiltered: {} as any,
        prevFilterState: {} as any,
        options: [{}] as any,
        filterValue: "",
        dateFilterValue: null,
        filters: {
            date: null,
            name: null
        },
        newFilters: {
            params: {
                start_date: moment().startOf('month').format("YYYY-MM-DD"),
                end_date: moment().endOf('month').format("YYYY-MM-DD")
            }
        } as Filters,
        subjects: [],
        examSubjects: [],
    };

    async componentDidMount() {
        this.fetchHistory();
        this.fetchSubjects();
        this.fetchExamSubjects();
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}, StaticContext, unknown>>, prevState: Readonly<HistoryPageState>, snapshot?: any): void {
        if (this.state.newFilters !== prevState.newFilters) {
            console.log(this.state.newFilters)
        }
    }

    fetchHistory = async (params: Filters = this.state.newFilters) => {
        const res = await http.get('lessons/history', params);
        let lessonHistory = res.data.data;

        lessonHistory = lessonHistory.map((lesson: any) => {
            const month = moment(lesson.date).date(1).format("MMMM, YYYY");
            return { ...lesson, month };
        });
        const months = lessonHistory.map((lesson: any) => lesson.month).filter(onlyUnique);
        const lessonHistoryByMonth: any = {};
        months.forEach((month: string) => {
            lessonHistoryByMonth[month] = lessonHistory.filter((lesson: any) => lesson.month === month);
        });
        console.log(lessonHistoryByMonth)
        this.setState({
            loaded: true,
            lessonHistoryByMonth,
            lessonHistoryFiltered: lessonHistoryByMonth
        });
    };

    fetchSubjects = () => {
        http.get(`driving-theme-subjects`).then((res: any) => {
            console.log(res)
            this.setState({ subjects: res.data.data });
        })
    };

    fetchExamSubjects = () => {
        http.get(`driving-exam-theme-subjects`).then((res: any) => {
            console.log(res)
            this.setState({ examSubjects: res.data.data });
        })
    };

    statuses: any = {
        "done": "Nodarbība notika",
        "student_cancelled": "Students atcēla",
        "student_cancelled24": "Students atcēla",
        "student_cancelled48": "Students atcēla",
        "instructor_cancelled": "Instruktors atcēla",
        "student_didnt_come": "Students neieradās",
        "exam_failed": "Skolas eksāmens nav nokārtots",
        "exam_passed": "Skolas eksāmens ir nokārtots"
    };

    render() {
        const { newFilters } = this.state;
        if (!this.state.loaded) {
            return <Layout style={{ minHeight: "100vh", }}><Content style={{ padding: 15 }}><Skeleton /></Content></Layout>;
        }

        // if (Object.keys(this.state.lessonHistoryByMonth).length === 0) {
        //     return <Layout className="main-content"><Content style={{ padding: 15, paddingTop: 100 }}><Empty /></Content></Layout>;
        // }

        const getAllHistory = Object.keys(this.state.lessonHistoryFiltered).map((month) => {
            return this.state.lessonHistoryByMonth[month];
        })

        const allHistoryStudentNames = getAllHistory.flatMap((item: any) => {
            return item.map((slot: any) => {
                const nameArr = { value: slot.student_name };
                return nameArr;
            });
        });

        const filteredStudentNames = allHistoryStudentNames.filter((item: any, index: any) => {
            return allHistoryStudentNames.findIndex((name: any) => name.value === item.value) === index;
        })

        // const inputHandler = (text: string) => {
        //     if (text !== "") {
        //         this.setState(
        //             {
        //                 options: filteredStudentNames.filter((item: any) => {
        //                     return item.value.toLowerCase().includes(text) || item.value.includes(text)
        //                 }),
        //                 filterValue: text
        //             }
        //         );

        //     }
        //     else {
        //         this.setState(
        //             {
        //                 filterValue: ""
        //             }
        //         );
        //     }
        // }

        // const searchHandler = (value: any) => {
        //     this.setState(
        //         {
        //             filters: {
        //                 name: value,
        //                 date: this.state.dateFilterValue !== null ? moment(this.state.dateFilterValue).date(1).format("MMMM, YYYY") : null
        //             },
        //         }, () => {
        //             filterLessons();
        //         })
        // }

        // const datePickHandler = (date: any, dateString: string) => {
        //     this.setState(
        //         {
        //             filters: {
        //                 name: this.state.filters.name,
        //                 date: moment(dateString).date(1).format("MMMM, YYYY")
        //             },
        //             dateFilterValue: date
        //         }, () => {
        //             filterLessons();
        //         })
        // }

        const filterLessons = () => {

            const arrayByName = Object.keys(this.state.lessonHistoryByMonth).map((month: any) => {
                return {
                    [month]: this.state.lessonHistoryByMonth[month].filter((slot: any) => {
                        if (this.state.filters.name === null) return slot;
                        const nameArr = slot.student_name;
                        if (nameArr === this.state.filters.name) {
                            return slot;
                        }
                    })
                }
            })
            // console.log(arrayByName)
            const arrayFiltered = arrayByName.filter((month: any) => {
                console.log(month)
                if (this.state.filters.date === null && month[Object.keys(month)[0]].length > 0) return month;
                if (this.state.filters.date === Object.keys(month)[0] && month[Object.keys(month)[0]].length > 0) {
                    return month;
                }
            })
            // console.log(arrayFiltered);
            if (arrayFiltered.length > 0) {
                const filteredItems = Object.assign({}, ...arrayFiltered);
                this.setState(
                    {
                        lessonHistoryFiltered: filteredItems
                    }
                );
            }
            else {
                alert('Nav ierakstu');
            }
        }

        const clearFilter = () => {
            this.setState(
                {
                    newFilters: {
                        params: {
                            start_date: moment().startOf('month').format("YYYY-MM-DD"),
                            end_date: moment().endOf('month').format("YYYY-MM-DD"),
                        }
                    }
                }
            );
            this.fetchHistory({
                params: {
                    start_date: moment().startOf('month').format("YYYY-MM-DD"),
                    end_date: moment().endOf('month').format("YYYY-MM-DD"),
                }
            })
        };

        const acceptFilter = () => {
            this.fetchHistory(newFilters)
        }

        return (
            <Layout className="main-content">
                <Switch>
                    <Route exact path="/history/:student_id/:student_lesson_id/exam">
                        <LessonInfo exam subjects={this.state.examSubjects} />
                    </Route>
                    <Route exact path="/history/:student_id/:student_lesson_id/testDrive">
                        <LessonInfo testDrive subjects={this.state.subjects} />
                    </Route>
                    <Route exact path="/history/:student_id/:student_lesson_id">
                        <LessonInfo subjects={this.state.examSubjects} />
                    </Route>
                    <Route exact path="/history">
                        <Content style={{ padding: 15 }}>
                            <Row>
                                <Col span={24} style={{ background: "#FFF", marginTop: 50, padding: 24 }} >
                                    <span style={{ marginRight: 15 }}>Atlasīt pēc vārda uzvārda</span>
                                    <Input
                                        style={{ width: 200, marginBottom: 10, display: "block" }}
                                        value={newFilters.params.student}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.setState({
                                                newFilters: {
                                                    params: {
                                                        ...newFilters.params,
                                                        student: e.target.value
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                    <div></div>
                                    <span style={{ marginRight: 15 }}>Atlasīt pēc tālruņa nr</span>
                                    <Input
                                        style={{ width: 200, marginBottom: 10, display: "block" }}
                                        value={newFilters.params.phone}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.setState({
                                                newFilters: {
                                                    params: {
                                                        ...newFilters.params,
                                                        phone: e.target.value
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                    {/* <AutoComplete
                                style={{
                                    width: 200,
                                    marginRight: 10
                                }}
                                value={this.state.filterValue}
                                options={this.state.options}
                                onChange={inputHandler}
                                onSelect={searchHandler}
                            /> */}
                                    <div>
                                        <span>Atlasīt pēc mēneša</span><br />
                                        <DatePicker.RangePicker
                                            style={{ width: 300, marginTop: 10 }}
                                            value={[moment(newFilters.params.start_date), moment(newFilters.params.end_date)]}
                                            onChange={(e) => {
                                                this.setState({
                                                    newFilters: {
                                                        params: {
                                                            ...newFilters.params,
                                                            start_date: moment(e ? e[0] : moment().startOf('month')).format("YYYY-MM-DD"),
                                                            end_date: moment(e ? e[1] : moment().endOf('month')).format("YYYY-MM-DD")
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                        {/* <DatePicker
                                    picker="month"
                                    style={{ marginTop: 5 }}
                                    onChange={datePickHandler}
                                    value={this.state.dateFilterValue}
                                    placeholder={"Izvēlies mēnesi"}
                                /> */}
                                    </div>
                                    <Button onClick={clearFilter} style={{ marginTop: 5, marginRight: 10 }} >Nodzēst filtrus</Button>
                                    <Button onClick={acceptFilter} style={{ marginTop: 5, marginRight: 10 }} >Meklēt</Button>
                                </Col>
                            </Row>
                            {Object.keys(this.state.lessonHistoryFiltered).map((month: string, index: number) => {
                                const lessons = this.state.lessonHistoryFiltered[month];
                                // console.log(lessons);
                                return (
                                    <Row key={index} gutter={20} style={{ marginBottom: 15 }}>
                                        <Col span={24}>
                                            {lessons.length > 0 ?
                                                (<Card title={<Space><span>{month}</span></Space>}>
                                                    {lessons.map((slot: any, index: number) => {
                                                        // const meeting_place = slot.instructor_service.city.find((el: any) => el.city_id === slot.city_id)?.meeting_place;
                                                        // const city = slot.instructor_service.cities.find((el: any) => el.id === slot.city_id)?.value;
                                                        // const studentLesson = slot.student_lessons[0];
                                                        // const name = slot.student_name;
                                                        // const date = slot.date;
                                                        // const time_from = moment(slot.time_from, "HH:mm:ss").format("HH:mm");
                                                        // const time_to = moment(slot.time_to, "HH:mm:ss").format("HH:mm");

                                                        const backgroundColor = slot.status === "done" || slot.status === "exam_passed" || slot.status === "exam_failed"
                                                            ? "#90ee90"
                                                            : "#f8d2d2";

                                                        function renderPopover() {
                                                            const content = <div style={{ padding: 5 }}>
                                                                <Rate value={slot?.rating} disabled></Rate>
                                                                {slot?.feedback !== "undefined" ? <div style={{ padding: 5, width: 250 }}>{slot?.feedback}</div> : null}
                                                            </div>;

                                                            return <Popover placement="left" content={content}>
                                                                <Button type="text" icon={<InfoCircleOutlined />} />
                                                            </Popover>
                                                        }

                                                        return <Card key={index} style={{ marginBottom: 5, backgroundColor, border: "1px solid transparent" }}>
                                                            <Row gutter={[20, 10]}>
                                                                <Col xs={{ span: 24 }} md={{ span: 5 }}><Badge status={slot.status === "done" ? "success" : "error"} text={<strong>{slot.student_name}</strong>} /></Col>
                                                                <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.date}</Col>
                                                                <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.timer}</Col>
                                                                <Col xs={{ span: 12 }} md={{ span: 3 }}>{slot.category} kategorija</Col>
                                                                <Col xs={{ span: 12 }} md={{ span: 5 }}>{slot.slot_city + ", " + slot.meeting_place}</Col>
                                                                <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                                                    <Space style={{ float: "right" }}>
                                                                        {/* {slot.status === "done" ? renderPopover() : null}
                                                                {
                                                                    slot.comment
                                                                        ? <Popover placement="left" content={
                                                                            <div style={{ padding: 5 }}>
                                                                                {slot.comment}
                                                                            </div>
                                                                        }>
                                                                            <Button type="text" icon={<InfoCircleOutlined />} />
                                                                        </Popover>
                                                                        : <></>
                                                                } */}
                                                                        <strong>{this.statuses[slot.status]}</strong>
                                                                        {
                                                                            slot.status === "done"
                                                                                || slot.status === "exam_passed"
                                                                                || slot.status === "exam_failed"
                                                                                ? <Link to={`/history/${slot.student_id}/${slot.student_lesson_id}${slot.status.includes("exam") ? '/exam' : !!slot.test_drive ? "/testDrive" : ''}`}>
                                                                                    <Button>
                                                                                        Atvērt
                                                                                    </Button>
                                                                                </Link>
                                                                                : <></>
                                                                        }
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    })}
                                                </Card>) : <></>}
                                        </Col>
                                    </Row>
                                );
                            })
                            }
                        </Content>
                    </Route>
                </Switch>
            </Layout >
        );
    }
}

export const HistoryPage = withRouter(BaseHistoryPage);
import { Card } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom';
import { http } from "../helpers/http"
import { AxiosResponse } from 'axios';
import { LessonInfoCard } from "./StudentStats/StudentStatsView/LessonsHistory/LessonInfo/LessonInfoCard";
import { ExamInfoCard } from "./StudentStats/StudentStatsView/LessonsHistory/LessonInfo/ExamInfoCard";

type Params = {
    student_id: string;
    student_lesson_id: string;
}

type LessonInfoProps = {
    exam?: boolean;
    testDrive?: boolean;
    subjects: any;
}

export const LessonInfo = ({ exam, testDrive, subjects }: LessonInfoProps) => {
    const { student_id, student_lesson_id } = useParams<Params>();
    const [lesson, setLesson] = useState({} as any);

    const getLesson = () => {
        http.get(`get-student-driving-evaluation-history-by-lesson/${student_lesson_id}`).then((res: AxiosResponse) => {
            console.log(res)
            setLesson(res.data.data.length > 0 ? res.data.data[0] : {})
        })
    };

    const getExam = () => {
        http.get(`get-student-driving-exam-evaluation-history-by-lesson/${student_lesson_id}`).then((res: AxiosResponse) => {
            console.log(res)
            setLesson(res.data.data.length > 0 ? res.data.data[0] : {})
        })
    };

    const getTestDrive = () => {
        http.get(`get-student-test-drive-evaluation-history-by-lesson/${student_lesson_id}`).then((res: AxiosResponse) => {
            console.log(res)
            setLesson(res.data.data.length > 0 ? res.data.data[0] : {})
        })
    };

    useEffect(() => {
        if (exam) {
            getExam();
            return;
        } if (testDrive) {
            getTestDrive();
            return;
        }
        getLesson();
    }, [])

    return <>
        <Card>
            <Link to={'/history'}>
                Atpakaļ
            </Link>
        </Card>
        <Card
            style={{ margin: 20 }}
            title={<Link to={`/student-stats/${lesson?.student_id}`}>
                {lesson?.student_name}
                <LinkOutlined style={{ marginLeft: 10 }} />
            </Link>}
        >
            {
                Object.keys(lesson).length > 0
                    ? exam
                        ? <ExamInfoCard
                            lesson={lesson}
                            subjects={subjects}
                        />
                        : <LessonInfoCard
                            lesson={lesson}
                            subjects={subjects}
                        />
                    : <></>
            }
        </Card>
    </>
}
import { Card, Col, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

export const SpecialManeuverForm = (props: any) => {
    // const [figures, setFigures] = useState([] as any);

    const getExamFigures = () => {
        http.get(`get-exam-figures`).then((res: any) => {
            console.log(res);
            props.setFigures(res.data.data.map((figure: any) => {
                return {
                    figure_lv: figure.figure_lv,
                    figure_en: figure.figure_en,
                    figure_ru: figure.figure_ru,
                    figure_id: figure.id,
                    grade_1: null,
                    grade_2: null,
                    grade_3: null
                }
            }))
        })
    };

    useEffect(() => {

    }, []);

    // useEffect(() => {
    //     props.setFigures(figures)
    // }, [figures]);

    useEffect(() => {
        if (!props.firstRender && props?.figures?.length === 0) {
            getExamFigures();
        } else {
            props.setFigures(props.figures)
        }
    }, [props.figures, props.firstRender])

    return <Card
        className="grey-title-card"
        title={<b style={{ color: "#FFFFFF" }}>2. Etaps - speciālais manevrs</b>}
        style={{ marginBottom: 20 }}
    >
        <ul style={{ padding: 0, listStyle: "none" }}>
            {
                props.figures?.map((question: any, index: any) => {
                    const gradesKeys = Object.keys(question).filter((key: any) => {
                        return key.includes('grade');
                    });
                    const passed = gradesKeys.some((el: any) => {
                        return !!question[el];
                    });
                    return <li key={index}>
                        <Card style={{ backgroundColor: passed ? "#d7f2d7" : "#FFFFFF" }}>
                            <Row justify="space-between">
                                <p style={{ margin: 12 }}>{question.figure_lv}</p>
                                <Row gutter={[12, 12]}>
                                    {
                                        Object.keys(question).filter((key: any) => {
                                            return key.includes('grade')
                                        }).map((key: any, index: any) => {
                                            if (index > 0) {
                                                return <></>
                                            }
                                            return <Col key={index}>
                                                <b>{index + 1}: </b><Select
                                                    style={{ width: 120 }}
                                                    value={question[key] === 1 ? "passed" : question[key] === 0 ? "failed" : undefined}
                                                    onChange={(e: any) => {
                                                        props.setFigures((prev: any) => {
                                                            const newArray = JSON.parse(JSON.stringify(prev)).map((figure: any) => {
                                                                if (figure.figure_id === question.figure_id) {
                                                                    return {
                                                                        ...figure,
                                                                        [key]: e === "passed" ? 1 : e === "failed" ? 0 : null,
                                                                        passed: e === "passed" ? 1 : 0
                                                                    }
                                                                } return figure;
                                                            })
                                                            return newArray;
                                                        })
                                                    }}
                                                >
                                                    <Select.Option value={-1}> </Select.Option>
                                                    <Select.Option value={"passed"}>Nokārtots</Select.Option>
                                                    <Select.Option value={"failed"}>Nav nokārtots</Select.Option>
                                                </Select>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Row>
                        </Card>
                    </li>
                })
            }
        </ul>
    </Card >
}
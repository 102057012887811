import { Button, Card, Col, Collapse, Layout, message, Modal, PageHeader, Radio, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { http } from "../../../helpers/http";
import { EvalHeader } from "../components/EvalHeader";
import { EvalCardLayout } from "./EvalCard/EvalCardLayout";
import { EvalFormQuestion } from "./EvalFormQuestion";
import { Map, MapSection } from "../StudentStatsView/LessonsHistory/MapStats/MapSection";
import { DrivingStats } from "../StudentStatsView/LessonsHistory/MapStats/DrivingStats";
import { StudentCard } from "./StudentCard/StudentCard";
import { FixedPointsForm } from "./LessonComments/FixedPointsForm";
import { FeedbackComment } from "./LessonComments/FeedbackComment";
import { BottomTabs } from "../MobileNav/BottomTabs";
import { LessonHistoryCard } from "../StudentStatsView/LessonsHistory/LessonsHistoryCard";
import { FinishEvalModal } from "./FinishEvalModal";
import { useGeolocated } from "react-geolocated";
import icFixPoint from "../../../img/ic-create-point.svg";
import moment from "moment";
//@ts-ignore
import Geocode from "react-geocode";
import { ExamsHistoryCard } from "../StudentStatsView/LessonsHistory/ExamsHistoryCard";

export const StudentEvalForm = (props: any) => {
    const { self, exam, testDrive } = props;

    const { id: slotID, studentID } = useParams() as any;
    const { state } = useLocation() as any;
    const history = useHistory();
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000
            },
            watchPosition: false,
            userDecisionTimeout: 5000,
            suppressLocationOnMount: true,
        });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY ?? "");
    Geocode.setLanguage("lv");
    Geocode.setRegion("lv");
    Geocode.setLocationType("ROOFTOP");
    const [studentLVL, setStudentLVL] = useState(null as any);
    const [gradeLvl, setGradeLvl] = useState<string>("");
    const [answers, setAnswers] = useState([] as any);
    const [scrolled, setScrolled] = useState({} as any);
    const [stickHead, setStickHead] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [activeMobileTab, setActiveMobileTab] = useState("" as any);
    const [themes, setThemes] = useState([] as any);
    const [mapPoints, setMapPoints] = useState([] as any);
    const [student, setStudent] = useState({} as any);
    const [studentRating, setStudentRating] = useState(0);
    const [subjects, setSubjects] = useState([] as any);
    const [subjectHistory, setSubjectHistory] = useState({} as any);
    const [studentHistory, setStudentHistory] = useState([] as any);
    const [examSubjects, setExamSubjects] = useState([] as any);
    const [studentExamHistory, setStudentExamHistory] = useState([] as any);
    const [studentTestDriveHistory, setStudentTestDriveHistory] = useState([] as any);
    const [feedback, setFeedback] = useState(null as any);
    const [finishModalVisible, setFinishModalVisible] = useState(false);
    const [examTheoryQuestion, setExamTheoryQuestion] = useState(undefined as any);
    const [figures, setFigures] = useState([] as any);
    const [path, setPath] = useState([] as any);
    const [firstRender, setFirstRender] = useState(true);
    const [hasMapon, setHasMapon] = useState({} as any);
    const [startTime, setStartTime] = useState(state?.startTime);
    const [realTimeLesson, setRealTimeLesson] = useState(false);
    const [wakeLock, setWakeLock] = useState(null as any);
    const [category, setCategory] = useState(null as number | null);
    const [counters, setCounters] = useState({} as any);
    const [examGiver, setExamGiver] = useState(undefined as any);
    const [currentPosition, setCurrentPosition] = useState(undefined as any);
    const [loadingMap, setLoadingMap] = useState(true);

    const fetchQuestions = () => {
        if (exam || testDrive) {
            http.get(`driving-exam-questions`).then((res: any) => {
                setThemes(res.data.data);
            })
            return;
        }
        http.get(`driving-themes`).then((res: any) => {
            setThemes(res.data.data);
        })
    };

    const getInstructorMaponStatus = () => {
        http.get(`mapon-status/${slotID}`).then((res: any) => {
            setHasMapon(res.data)
        })
    }

    const getExamGiver = () => {
        http.get(`exam-giver/${slotID}`).then((res: any) => {
            setExamGiver(res.data)
        })
    }

    const getLesson = () => {
        http.get(`get-datetime-slot/${slotID}`).then((res: any) => {
            const { data } = res.data;
            if (Object.keys(data).length === 0) {
                setRealTimeLesson(false)
                return;
            }
            getStudentInfo(data.category_id);
            // setCategory(data.category_id)
            if (moment(data.date).isSame(moment(), "date")) {
                const lessonEndTime = data.time_to;
                console.log(lessonEndTime, startTime, moment().format("HH:mm:ss"))
                if (moment().isBetween(moment(startTime, "HH:mm:ss"), moment(lessonEndTime, "HH:mm:ss"), "minutes")
                    || moment().isSame(moment(startTime, "HH:mm:ss"), "minutes")
                    || moment().isSame(moment(lessonEndTime, "HH:mm:ss"), "minutes")) {
                    setRealTimeLesson(true)
                    return
                }
            }
            setRealTimeLesson(false)
        })
    }

    const getStudentInfo = (category: number) => {
        http.get(`get-student-info/${studentID}`, { params: { category: category } }).then((res: any) => {
            setStudent(res.data.data[0])
            setCategory(res.data.data[0].category_id)
        })
    }

    const getStudentCounters = () => {
        http.get(`get-student-driving-info/${studentID}/${student.category_id}`).then((res: any) => {
            setCounters(res.data.data[0]);
        })
    };

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            getStudentCounters();
        }
    }, [student])

    const getStudentEvalHistory = () => {
        http.get(`get-student-driving-evaluation-history/${studentID}`, { params: { category } }).then((res: any) => {
            setStudentHistory(res.data.data);
            setSubjectHistory(res.data.theme_subject_history);

            http.get(`get-student-driving-exam-evaluation-history/${studentID}`, { params: { category } }).then((response: any) => {
                if (response.data.data.length > 0) {
                    setGradeLvl("exam_worthy");
                    return;
                }
                const copyData = JSON.parse(JSON.stringify(res.data.data));
                const lastEvaluatedLesson = copyData.reverse().find((el: any) => {
                    return el?.status.includes("done") && el?.grade;
                });
                setGradeLvl(
                    lastEvaluatedLesson
                        ? lastEvaluatedLesson.grade
                        : "beginner"
                )
            })
        })
    };

    const getExamEvalHistory = () => {
        http.get(`get-student-driving-exam-evaluation-history/${studentID}`, { params: { category } }).then((res: any) => {
            const newData = res.data.data.map((el: any) => {
                return {
                    ...el,
                    exam_giver: res.data.exam_giver?.[el.student_lesson_id]
                }
            })
            setStudentExamHistory(newData);
        })
    };

    const getTestDriveEvalHistory = () => {
        http.get(`get-student-test-drive-evaluation-history/${studentID}`, { params: { category } }).then((res: any) => {
            console.log(res)
            // const newData = res.data.data.map((el: any) => {
            //     return {
            //         ...el
            //     }
            // })
            setStudentTestDriveHistory(res.data.data);
        })
    };

    const fetchExamSubjects = () => {
        http.get(`driving-exam-theme-subjects`, { params: { category } }).then((res: any) => {
            setExamSubjects(res.data.data);
        })
    };

    const fetchSubjects = () => {
        http.get(`driving-theme-subjects`).then((res: any) => {
            setSubjects(res.data.data);
        })
    };


    const getStudentEvalRating = () => {
        http.get(`get-student-driving-evaluation-rating/${studentID}`, { params: { category } }).then((res: any) => {
            setStudentRating(res.data)
        })
    };

    const stickElementsToTop = (yScrolled: any) => {
        setStickHead(yScrolled > 36)
    }

    const newMapPoint = async () => {
        let pointCoords = await getCurrentPosition();
        const hasThatPoint = mapPoints.some((point: any) => {
            return point.lat === pointCoords.lat && point.lng === pointCoords.lng
        })
        if (hasThatPoint) {
            message.error("Šajā vietā jau ir pievienots punkts!")
            return;
        }
        let formated_address = ""
        await Geocode.fromLatLng(pointCoords.lat, pointCoords.lng).then(
            (response: any) => {
                const address = response.results[0].formatted_address;
                formated_address = address;
            },
            (error: any) => {
                console.error(error);
            }
        );
        setMapPoints((prev: any) => {
            return [
                ...prev,
                {
                    lat: pointCoords?.lat,
                    lng: pointCoords?.lng,
                    action_time: moment().format("HH:mm"),
                    speed: pointCoords?.speed,
                    comment: null,
                    formated_address
                }
            ]
        })
        message.success('Jūsu atrašanās vieta veiksmīgi reģistrēta kartē')
    };

    const getCurrentPosition = async () => {
        let pointCoords = {} as any;
        if (hasMapon?.status) {
            await http.get(`get-current-location/${hasMapon.service_id}`).then((res: any) => {
                const { data } = res.data;
                pointCoords = {
                    lat: data[0]?.lat,
                    lng: data[0]?.lng,
                    speed: data[0]?.speed
                }

            })
        } else {
            pointCoords = {
                lat: coords?.latitude,
                lng: coords?.longitude,
                speed: coords?.speed
            }
        }
        return pointCoords;
    }

    const removeMapPoint = (ind: any) => {
        setMapPoints((prev: any) => {
            return prev.filter((point: any, index: any) => {
                return index !== ind
            })
        })
    };

    const updateMapPoint = (ind: any, data: string) => {
        setMapPoints((prev: any) => {
            return prev.map((point: any, index: any) => {
                if (index === ind) {
                    return {
                        ...point,
                        comment: data
                    }
                } return point
            })
        })
    };

    const saveChangesToStorage = () => {
        const toSave = [{
            lesson_id: slotID,
            mapPoints,
            answers,
            figures,
            studentLVL,
            feedback,
            examTheoryQuestion,
            save_date: moment().format("YYYY-MM-DD"),
            startTime: startTime
        }]
        const prevSaveString = localStorage.getItem("stats");
        if (prevSaveString) {
            const prevSave = JSON.parse(prevSaveString);
            const haveSave = prevSave.some((save: any) => save.lesson_id === slotID)
            if (haveSave) {
                const newArray = prevSave.map((save: any) => {
                    if (save.lesson_id === slotID) {
                        return toSave[0]
                    }
                    return save
                })
                localStorage.setItem("stats", JSON.stringify(newArray));
            } else {
                localStorage.setItem("stats", JSON.stringify([...prevSave, ...toSave]));
            }
            return;
        }
        localStorage.setItem("stats", JSON.stringify(toSave));
    }

    const getChangesFromStorage = () => {
        clearStorage();
        const save = localStorage.getItem("stats");
        if (save) {
            const parsedSave = JSON.parse(save);
            const haveSave = parsedSave.find((save: any) => save.lesson_id === slotID);
            console.log(haveSave)
            if (haveSave !== undefined) {
                setMapPoints(haveSave.mapPoints);
                setAnswers(haveSave.answers);
                setFigures(haveSave.figures);
                setStudentLVL(haveSave.studentLVL);
                setFeedback(haveSave.feedback);
                setExamTheoryQuestion(haveSave.examTheoryQuestion);
                setStartTime(haveSave.startTime)
            }
        }
        setFirstRender(false);
    }

    const clearStorage = () => {
        const statsString = localStorage.getItem("stats");
        if (statsString) {
            const stats = JSON.parse(statsString);
            if (moment(stats[0].save_date, "YYYY-MM-DD").isBefore(moment(), "day")) {
                localStorage.removeItem("stats");
            }
        }
    }

    const wakeLockTry = async () => {
        try {
            //@ts-ignore
            setWakeLock(await navigator.wakeLock.request('screen'));
        } catch (err: any) {
            // the wake lock request fails - usually system related, such being low on battery
            console.log(`${err.name}, ${err.message}`);
        }
    }

    const setStartPosition = async () => {
        setLoadingMap(true)
        const { lat, lng } = await getCurrentPosition() as any;
        setLoadingMap(false)
        setCurrentPosition({ lat, lng });
    }

    useEffect(() => {
        if (category) {
            getInstructorMaponStatus();
            fetchQuestions();
            getStudentEvalHistory();
            getExamEvalHistory();
            getTestDriveEvalHistory();
            fetchSubjects();
            fetchExamSubjects();
            getStudentEvalRating();
            getChangesFromStorage();
            getInstructorMaponStatus();
            setIsMobile(window.matchMedia("(max-width: 767px)").matches)

            window.addEventListener("resize", () => {
                setIsMobile(window.matchMedia("(max-width: 767px)").matches)
            })
            window.addEventListener("scroll", (event: any) => {
                setScrolled(event);
            })
            wakeLockTry();
        } else {
            getLesson();
        }
        return () => {
            window.removeEventListener("scroll", (event: any) => {
                setScrolled(Math.random());
            });
        }
    }, [category]);

    useEffect(() => {
        stickElementsToTop(window.pageYOffset)
    }, [scrolled]);

    useEffect(() => {
        return () => {
            if (wakeLock) {
                wakeLock.release();
            }
        }
    }, [wakeLock])

    useEffect(() => {
        if (coords !== undefined) {
            newMapPoint();
        }
    }, [coords])

    useEffect(() => {
        if (!firstRender) {
            saveChangesToStorage();
        }
    }, [mapPoints, answers, figures, studentLVL, feedback, examTheoryQuestion]);

    useEffect(() => {
        if (exam) {
            getExamGiver();
        }
    }, [exam]);

    useEffect(() => {
        if (Object.keys(hasMapon).length > 0 && currentPosition === undefined) {
            setStartPosition();
        }
    }, [hasMapon])

    return <Layout className="main-content" style={{ paddingBottom: isMobile ? 100 : 0 }}>
        {
            realTimeLesson && (hasMapon?.status || isGeolocationAvailable || isGeolocationAvailable)
                ? isMobile
                && <button
                    className="register-point-btn-rnd"
                    onClick={() => hasMapon?.status ? newMapPoint() : getPosition()}
                >
                    <img src={icFixPoint} />
                </button>
                : <></>
        }
        {
            finishModalVisible
            && <FinishEvalModal
                visible={finishModalVisible}
                setVisible={(state: boolean) => setFinishModalVisible(state)}
                studentID={studentID}
                slotID={slotID}
                answers={answers}
                studentLVL={studentLVL}
                mapPoints={mapPoints}
                feedback={feedback}
                exam={exam}
                testDrive={testDrive}
                figures={figures}
                examTheoryQuestion={examTheoryQuestion}
                startTime={startTime}
            />
        }
        <EvalHeader
            isMobile={isMobile}
            setActiveTab={setActiveMobileTab}
            student={`${student?.first_name || ""} ${student?.last_name || ""}`}
        />
        {/* <b>Start time{startTime}</b> */}
        {
            activeMobileTab === "history"
                ? <>
                    {
                        studentTestDriveHistory.length > 0
                        && <ExamsHistoryCard
                            isMobile={isMobile}
                            studentHistory={studentTestDriveHistory}
                            subjects={examSubjects}
                            testDrive
                        />
                    }
                    {
                        studentExamHistory.length > 0
                        && <ExamsHistoryCard
                            isMobile={isMobile}
                            studentHistory={studentExamHistory}
                            subjects={examSubjects}
                        />
                    }
                    <LessonHistoryCard
                        isMobile={isMobile}
                        studentHistory={studentHistory}
                        subjects={subjects}
                    />
                </>
                : <Row>
                    <Col span={24} lg={24} xl={14} xxl={16}>
                        {
                            examGiver && (examGiver?.instructor || examGiver?.admin)
                            && <Card
                                style={{ margin: 20 }}
                                title="Eksāmena informācija"
                            >
                                {
                                    <p>
                                        <b>Uz eksāmenu novirzīja:</b> {examGiver?.instructor ? `Instruktors ${examGiver?.instructor}` : examGiver?.admin ? `Administrators ${examGiver?.admin}` : "Nav zināms"}
                                    </p>
                                }
                            </Card>
                        }
                        {
                            !isMobile
                                ? <>

                                    <EvalCardLayout
                                        isMobile={isMobile}
                                        answers={answers}
                                        examTheoryQuestion={examTheoryQuestion}
                                        setExamTheoryQuestion={setExamTheoryQuestion}
                                        figures={figures}
                                        setFigures={setFigures}
                                        firstRender={firstRender}
                                        setAnswers={setAnswers}
                                        themes={themes}
                                        returnStudentLvl={setStudentLVL}
                                        studentLVL={studentLVL}
                                        subjectHistory={subjectHistory}
                                        exam={exam}
                                        testDrive={testDrive}
                                        student={student}
                                    />
                                    <FixedPointsForm
                                        points={mapPoints}
                                        removePoint={(ind: any) => removeMapPoint(ind)}
                                        updatePoint={(ind: any, data: string) => updateMapPoint(ind, data)}
                                    />
                                    <FeedbackComment
                                        setFeedback={setFeedback}
                                        feedback={feedback}
                                    />
                                    <Row gutter={20} style={{ marginBottom: 40, padding: "0 20px" }}>
                                        <Col span={8}>
                                            <Button
                                                style={{ width: "100%" }}
                                                onClick={() => history.goBack()}
                                            >
                                                Atcelt vērtēšanu
                                            </Button>
                                        </Col>
                                        <Col span={16}>
                                            <Button
                                                style={{ width: "100%" }}
                                                onClick={() => setFinishModalVisible(true)}
                                                disabled={Number(counters?.done) <= 4
                                                    ? answers.length < 4
                                                    : answers.length < 7
                                                }
                                            >
                                                Pabeigt nodarbību
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                                : activeMobileTab === "evaluation"
                                    ? <>
                                        <EvalCardLayout
                                            isMobile={isMobile}
                                            answers={answers}
                                            examTheoryQuestion={examTheoryQuestion}
                                            setExamTheoryQuestion={setExamTheoryQuestion}
                                            figures={figures}
                                            setFigures={setFigures}
                                            firstRender={firstRender}
                                            setAnswers={setAnswers}
                                            themes={themes}
                                            returnStudentLvl={setStudentLVL}
                                            studentLVL={studentLVL}
                                            subjectHistory={subjectHistory}
                                            exam={exam}
                                            testDrive={testDrive}
                                            student={student}
                                        />
                                        <FeedbackComment
                                            setFeedback={setFeedback}
                                            feedback={feedback}
                                        />
                                        <Row gutter={20} style={{ marginBottom: 40, padding: "0 20px" }}>
                                            <Col span={24} md={8}>
                                                <Button
                                                    style={{ width: "100%", marginBottom: 20 }}
                                                    onClick={() => history.goBack()}
                                                >
                                                    Atcelt vērtēšanu
                                                </Button>
                                            </Col>
                                            <Col span={24} md={16}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    onClick={() => setFinishModalVisible(true)}
                                                    disabled={Number(counters?.done) <= 4
                                                        ? answers.length < 4
                                                        : answers.length < 7
                                                    }
                                                >
                                                    Pabeigt nodarbību
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                    : activeMobileTab === "lesson"
                                        ? <Card
                                            className="grey-title-card"
                                            style={{ margin: isMobile ? 10 : 20, marginLeft: isMobile ? 10 : 0 }}
                                            title={<b style={{ color: "#FFFFFF" }}>Braukšanas atskaite</b>}
                                        >
                                            <Map points={mapPoints} path={path} currentPosition={currentPosition} loading={loadingMap} />
                                            {
                                                realTimeLesson
                                                    ? hasMapon?.status
                                                        ? <button
                                                            className="register-point-btn"
                                                            onClick={newMapPoint}
                                                        >
                                                            Fiksēt punktu kartē
                                                        </button>
                                                        : !isGeolocationAvailable
                                                            ? <b style={{ color: "red" }}>Jūsu pārlūks neatbalsta atrašanās vietas noteikšanu!</b>
                                                            : !isGeolocationEnabled
                                                                ? <div style={{ border: "2px solid red", padding: 10 }}>
                                                                    <b>Atrašanās vietas noteikšana nav ieslēgta!</b>
                                                                </div>
                                                                : <button
                                                                    className="register-point-btn"
                                                                    onClick={getPosition}
                                                                >
                                                                    Fiksēt punktu kartē
                                                                </button>
                                                    : <></>
                                            }
                                            <DrivingStats />
                                            <FixedPointsForm
                                                points={mapPoints}
                                                removePoint={(ind: any) => removeMapPoint(ind)}
                                                updatePoint={(ind: any, data: string) => updateMapPoint(ind, data)}
                                            />
                                        </Card>
                                        : activeMobileTab === "student"
                                            ? <StudentCard student={student} rating={studentRating} maxPoints={subjects.length} gradeLVL={gradeLvl} counters={counters} />
                                            : activeMobileTab === "history"
                                                ? "History"
                                                : <></>
                        }

                    </Col>
                    <Col span={24} lg={24} xl={10} xxl={8}>
                        {
                            isMobile
                                ? <></>
                                : <>
                                    <Card
                                        className="grey-title-card"
                                        style={{ margin: isMobile ? 10 : 20, marginLeft: isMobile ? 10 : 0 }}
                                        title={<b style={{ color: "#FFFFFF" }}>Braukšanas atskaite</b>}
                                    >
                                        <Map points={mapPoints} path={path} currentPosition={currentPosition} loading={loadingMap} />
                                        {
                                            realTimeLesson
                                                ? hasMapon?.status
                                                    ? <button
                                                        className="register-point-btn"
                                                        onClick={newMapPoint}
                                                    >
                                                        Fiksēt punktu kartē
                                                    </button>
                                                    : !isGeolocationAvailable
                                                        ? <b style={{ color: "red" }}>Jūsu pārlūks neatbalsta atrašanās vietas noteikšanu!</b>
                                                        : !isGeolocationEnabled
                                                            ? <div style={{ border: "2px solid red", padding: 10 }}>
                                                                <b>Atrašanās vietas noteikšana nav ieslēgta!</b>
                                                            </div>
                                                            : <button
                                                                className="register-point-btn"
                                                                onClick={getPosition}
                                                            >
                                                                Fiksēt punktu kartē
                                                            </button>
                                                : <></>
                                        }
                                        <DrivingStats />
                                    </Card>
                                    <StudentCard student={student} rating={studentRating} maxPoints={subjects.length} gradeLVL={gradeLvl} counters={counters} />
                                </>
                        }
                    </Col>
                </Row>
        }
        {
            isMobile
            &&
            <BottomTabs
                activeTab={setActiveMobileTab}
            />
        }
    </Layout >
}

